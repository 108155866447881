.main-part-footer {
  .upper-part {
    display: flex;
    flex-direction: column;
    gap: 40px;
    .partfirst {
      margin: auto;
      border-radius: 20px;
      background: linear-gradient(286deg, #1f5ee6 38.81%, #1f7be6 81.41%);
      width: 972px;
      margin-top: 88px;
      .leftdiv-footer {
        display: flex;
        width: 566px;
        flex-direction: column;
        padding: 40px;
        align-items: flex-start;
        gap: 12px;
        .heading {
          color: var(--Primary, #1f5ee6);
          
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          display: flex;
          padding: 6px 11px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 5px;
          background: var(--White, #fff);
        }
        .subheading {
          color: var(--White, #fff);
          
          font-size: 30px;
          font-style: normal;
          font-weight: 500;
          align-self: stretch;
        }
        .content {
          color: rgba(255, 255, 255, 0.68);
          
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          align-self: stretch;
        }
      }
      .rightdiv-footer {
        width: 330px;
        height: 312px;
        position: relative;
        margin-left: auto;
        margin-top: -318px;
        img {
          width: 100%;
        }
      }
    }
    .partsecond {
      margin: auto;
      border-radius: 20px;
      background: linear-gradient(286deg, #1f5ee6 38.81%, #1f7be6 81.41%);
      width: 972px;
      display: flex;
      .leftdiv-footer {
        width: 548px;
        padding: 40px;
        color: var(--White, #fff);
        
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
      }
      .rightdiv-footer {
        display: flex;
        grid-area: 10px;
        margin-right: 25px;
        .apple-img {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          height: 37px;
          width: 150px;
          border-radius: 13px;
          background: #000;
          margin: auto;
          margin-right: 20px;
          img {
            width: 100%;
          }
        }
        .google-img {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          height: 37px;
          width: 150px;
          border-radius: 13px;
          background: #000;
          margin: auto;
          margin-right: 20px;
          img {
            width: 100%;
          }
        }
      }
    }
  }
  .middle-part {
    background-color: #1a1a1a;
    padding: 100px;
    padding-left: 55px;
    display: flex;
    justify-content: space-evenly;
    margin-top: -92px;
    padding-top: 170px;
    .social-part {
      width: 30%;
      height: 251px;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      gap: 15px;
      align-items: baseline;
      .logo-company-name {
        display: flex;
        align-items: center;
        gap: 20px;
        .company-logo {
          width: 48px;
          flex-shrink: 0;
          img {
            width: 100%;
          }
        }
        .company-name {
          color: var(--White, #fff);
          font-family: Lexend;
          font-size: 35px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      .text {
        color: var(--Main-Subtext, #909090);
        
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 385px;
      }
      .social-media-icons {
        display: flex;
        gap: 20px;
        .icons {
          width: 44px;
          height: 44px;
          flex-shrink: 0;
          border-radius: 9px;
          background: var(--Text, #252525);
          img {
            width: 100%;
          }
        }
      }
    }
    .links-divs {
      display: flex;
      justify-content: space-around;
      width: 70%;
      align-items: baseline;

      .important-links1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;
        margin-left: 100px;
        .heading {
          color: var(--Main-Subtext, #909090);
          
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 1.6px;
        }
        .multiple-links {
          display: flex;
          flex-direction: column;
          gap: 16px;
          font-size: 22px;
          justify-content: center;
          .links {
            color: var(--White, #fff);
            
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
          }
        }
      }
      .important-links2 {
        display: flex;
        flex-direction: column;
        // justify-content: baseline;
        gap: 20px;
        .heading {
          color: var(--Main-Subtext, #909090);
          
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 1.6px;
        }
        .multiple-links {
          display: flex;
          flex-direction: column;
          gap: 20px;
          font-size: 18px;
          justify-content: center;
          .links {
            color: var(--White, #fff);
            
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
          }
        }
      }
      .important-links3 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;
        margin-right: -99px;
        .heading {
          color: var(--Main-Subtext, #909090);
          
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 1.6px;
        }
        .multiple-links {
          display: flex;
          flex-direction: column;
          gap: 20px;
          font-size: 16px;
          justify-content: center;
          .links {
            color: var(--White, #fff);
            
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
          }
        }
      }
    }
  }
  .lower-part {
    background: #282828;
    display: flex;
    justify-content: space-between;
    padding: 20px 20px;
    .left-lower-part {
      color: var(--White, #fff);
      font-family: "DM Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      margin-left: 10px;
    }
    .right-lower-part {
      display: inline-flex;
      justify-content: flex-end;
      align-items: center;
      gap: 25px;
      margin-right: 10px;
      .footer-links {
        color: var(--Main-Subtext, #909090);
        
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 348.98px) {
  .main-part-footer {
    .upper-part {
      display: flex;
      flex-direction: column;
      gap: 40px;
      .partfirst {
      width: 80%;
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .leftdiv-footer {
          width: 100%;
          padding: 0px;
          gap: 20px;
          .heading {
            font-size: 16px;
            display: block;
            margin-left: 20px;
            margin-top: 40px;
            font-weight: 600;
            padding: 8px 8px;
          }
          .subheading {
            margin-left: 20px;
            font-size: 18px;
          }
          .content {
            font-size: 14px;
            width: 88%;
            margin-left: 20px;
            margin-bottom: 120px;
          }
        }
        .rightdiv-footer {
          width: 50%;
          height: auto;
          position: absolute;
          margin: auto;
          margin-top: 263px;
        }
      }
      .partsecond {
        width: 80%;
        display: block;
        margin-top: 70px;
        .leftdiv-footer {
          width: 84%;
          font-size: 18px;
          padding: 20px;
        }
        .rightdiv-footer {
          display: flex;
          grid-area: 10px;
          margin: auto;
          margin-bottom: 40px;
          max-width: 80%;
          .apple-img {
            padding: 0px;
            height: 33px;
            width: 90px;
            margin-right: auto;
            border: none;
          }
          .google-img {
            padding: 0px;
            height: 33px;
            width: 90px;
            margin-right: auto;
            border: none;
          }
        }
      }
    }
    .middle-part {
      padding: 114px;
      padding-left: 0px;
      display: block;
      margin-top: -61px;
      padding-top: 130px;
      text-align: left;
      padding-bottom: 25px;
      border-bottom: 1.6px solid #828282;
      background-color: #111111;
      .social-part {
        width:90%;
        gap: 35px;
        margin-left: 30px;
        .logo-company-name {
          display: flex;
          align-items: center;
          gap: 10px;
          .company-logo {
            width: 32px;
            height: 24px;
            img {
              width: 100%;
            }
          }
          .company-name {
            font-size: 20px;
          }
        }
        .text {
          font-size: 14px;
          width: 100%;
          color: var(--White, #FFF);
          line-height: 18px;
        }
        .social-media-icons {
          display: flex;
          gap: 20px;
          .icons {
            width: 32px;
            height: 32px;
          }
        }
      }
      .links-divs {
        display: block;
        justify-content: space-around;
        width: 70%;
        align-items: baseline;
  
        .important-links1 {
          justify-content: left;
          gap: 15px;
          margin-left: 30px;
          .heading {
            font-size: 16px;
            display: none;
          }
          .multiple-links {
            gap: 15px;
            display: none;
            .links {
              font-size: 15px;
              line-height: 32px;      
            }
          }
        }
        .important-links2 {
          margin-top: 30px;
          margin-left: 30px;
          gap: 15px;
          .heading {
            font-size: 15px;
            display: none;
          }
          .multiple-links {
            gap: 15px;
            font-size: 15px;
            .links {
              font-size: 15px;
              line-height: 32px;      
            }
          }
        }
        .important-links3 {
          gap: 15px;
          margin-left: 30px;
          margin-top: 30px;
          .heading {
            font-size: 15px;
            display: none;
          }
          .multiple-links {
            gap: 15px;
            font-size: 15px;
            .links {        
              font-size: 15px;
              line-height: 32px;      
            }
          }
        }
      }
    }
    .lower-part {
      background: #111111;
      display: block;
      justify-content: space-between;
      padding: 0px;
      padding-top: 30px;
      padding-bottom: 30px;
      padding-left: 20px;
      .left-lower-part {
        color: var(--White, #fff);
        font-family: "DM Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        margin-left: 10px;
      }
      .right-lower-part {
        display: none;
      }
    }
  }
}

// Small devices (portrait phones, less than 576px)
@media (min-width: 349px) and (max-width: 575.98px) {
  .main-part-footer {
    .upper-part {
      display: flex;
      flex-direction: column;
      gap: 40px;
      .partfirst {
        width: 80%;
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .leftdiv-footer {
          width: 100%;
          padding: 0px;
          gap: 20px;
          .heading {
            font-size: 16px;
            display: block;
            margin-left: 20px;
            margin-top: 40px;
            font-weight: 600;
            padding: 8px 8px;
          }
          .subheading {
            margin-left: 20px;
            font-size: 18px;
          }
          .content {
            font-size: 14px;
            width: 88%;
            margin-left: 20px;
            margin-bottom: 120px;
          }
        }
        .rightdiv-footer {
          width: 40%;
          height: auto;
          position: absolute;
          margin: auto;
          margin-top: 263px;
        }
      }
      .partsecond {
        width: 80%;
        display: block;
        margin-top: 70px;
        .leftdiv-footer {
          width: 84%;
          font-size: 18px;
          padding: 20px;
        }
        .rightdiv-footer {
          display: flex;
          grid-area: 10px;
          margin: auto;
          margin-bottom: 40px;
          max-width: 65%;
          .apple-img {
            padding: 0px;
            height: 33px;
            width: 90px;
            margin-right: auto;
            border: none;
          }
          .google-img {
            padding: 0px;
            height: 33px;
            width: 90px;
            margin-right: auto;
            border: none;
          }
        }
      }
    }
    .middle-part {
      padding: 0px;
      padding-left: 0px;
      display: block;
      margin-top: -61px;
      padding-top: 130px;
      text-align: left;
      padding-bottom: 25px;
      border-bottom: 1.6px solid #828282;
      background-color: #111111;
      .social-part {
        width:90%;
        gap: 35px;
        margin-left: 30px;
        .logo-company-name {
          display: flex;
          align-items: center;
          gap: 10px;
          .company-logo {
            width: 32px;
            height: 24px;
            img {
              width: 100%;
            }
          }
          .company-name {
            font-size: 20px;
          }
        }
        .text {
          font-size: 14px;
          width: 100%;
          color: var(--White, #FFF);
          line-height: 18px;
        }
        .social-media-icons {
          display: flex;
          gap: 20px;
          .icons {
            width: 32px;
            height: 32px;
          }
        }
      }
      .links-divs {
        display: block;
        justify-content: space-around;
        width: 70%;
        align-items: baseline;
  
        .important-links1 {
          justify-content: left;
          gap: 15px;
          margin-left: 30px;
          .heading {
            font-size: 16px;
            display: none;
          }
          .multiple-links {
            gap: 15px;
            display: none;
            .links {
              font-size: 15px;
              line-height: 32px;      
            }
          }
        }
        .important-links2 {
          margin-top: 30px;
          margin-left: 30px;
          gap: 15px;
          .heading {
            font-size: 15px;
            display: none;
          }
          .multiple-links {
            gap: 15px;
            font-size: 15px;
            .links {
              font-size: 15px;
              line-height: 32px;      
            }
          }
        }
        .important-links3 {
          gap: 15px;
          margin-left: 30px;
          margin-top: 30px;
          .heading {
            font-size: 15px;
            display: none;
          }
          .multiple-links {
            gap: 15px;
            font-size: 15px;
            .links {        
              font-size: 15px;
              line-height: 32px;      
            }
          }
        }
      }
    }
    .lower-part {
      background: #111111;
      display: block;
      justify-content: space-between;
      padding: 0px;
      padding-top: 30px;
      padding-bottom: 30px;
      padding-left: 20px;
      .left-lower-part {
        color: var(--White, #fff);
        font-family: "DM Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        margin-left: 10px;
      }
      .right-lower-part {
        display: none;
      }
    }
  }
}

// Medium devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  .main-part-footer {
    .upper-part {
      display: flex;
      flex-direction: column;
      gap: 40px;
      .partfirst {
        width: 80%;
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .leftdiv-footer {
          width: 100%;
          padding: 0px;
          gap: 20px;
          .heading {
            font-size: 16px;
            display: block;
            margin-left: 20px;
            margin-top: 40px;
            font-weight: 600;
            padding: 8px 8px;
          }
          .subheading {
            margin-left: 20px;
            font-size: 23px;
          }
          .content {
            font-size: 16px;
            width: 88%;
            margin-left: 20px;
            margin-bottom: 120px;
          }
        }
        .rightdiv-footer {
          width: 40%;
          height: auto;
          position: absolute;
          margin: auto;
          margin-top: 302px;
        }
      }
      .partsecond {
        width: 80%;
        display: flex;
        margin-top: 140px;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .leftdiv-footer {
          width: 65%;
          font-size: 23px;
          margin: auto;
          padding: 40px;
        }
        .rightdiv-footer {
          display: flex;
          grid-area: 10px;
          margin-bottom: 40px;
          .apple-img {
            padding: 0px;
        height: 44px;
        width: 120px;
        margin-right: 48px;
        border: none;
          }
          .google-img {
            padding: 0px;
            height: 44px;
            width: 120px;
            border: none;
          }
        }
      }
    }
    .middle-part {
      padding: 0px;
      padding-left: 0px;
      display: block;
      margin-top: -61px;
      padding-top: 130px;
      text-align: left;
      padding-bottom: 25px;
      border-bottom: 1.6px solid #828282;
      background-color: #111111;
      .social-part {
        width:90%;
        gap: 35px;
        margin-left: 30px;
        .logo-company-name {
          display: flex;
          align-items: center;
          gap: 10px;
          .company-logo {
            width: 32px;
            height: 24px;
            img {
              width: 100%;
            }
          }
          .company-name {
            font-size: 20px;
          }
        }
        .text {
          font-size: 14px;
          width: 100%;
          color: var(--White, #FFF);
          line-height: 18px;
        }
        .social-media-icons {
          display: flex;
          gap: 20px;
          .icons {
            width: 32px;
            height: 32px;
          }
        }
      }
      .links-divs {
        display: block;
        justify-content: space-around;
        width: 70%;
        align-items: baseline;
  
        .important-links1 {
          justify-content: left;
          gap: 15px;
          margin-left: 30px;
          .heading {
            font-size: 16px;
            display: none;
          }
          .multiple-links {
            gap: 15px;
            display: none;
            .links {
              font-size: 15px;
              line-height: 32px;      
            }
          }
        }
        .important-links2 {
          margin-top: 30px;
          margin-left: 30px;
          gap: 15px;
          .heading {
            font-size: 15px;
            display: none;
          }
          .multiple-links {
            gap: 15px;
            font-size: 15px;
            .links {
              font-size: 15px;
              line-height: 32px;      
            }
          }
        }
        .important-links3 {
          gap: 15px;
          margin-left: 30px;
          margin-top: 30px;
          .heading {
            font-size: 15px;
            display: none;
          }
          .multiple-links {
            gap: 15px;
            font-size: 15px;
            .links {        
              font-size: 15px;
              line-height: 32px;      
            }
          }
        }
      }
    }
    .lower-part {
      background: #111111;
      display: block;
      justify-content: space-between;
      padding: 0px;
      padding-top: 30px;
      padding-bottom: 30px;
      padding-left: 20px;
      .left-lower-part {
        color: var(--White, #fff);
        font-family: "DM Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        margin-left: 10px;
      }
      .right-lower-part {
        display: none;
      }
    }
  }
}

// Large devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  .main-part-footer {
    .upper-part {
      display: flex;
      flex-direction: column;
      gap: 40px;
      .partfirst {
        width: 80%;
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .leftdiv-footer {
          display: flex;
          width: 566px;
          flex-direction: column;
          padding: 40px;
          align-items: flex-start;
          gap: 12px;
          .heading {
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            padding: 10px 20px;
          }
          .subheading {
            color: var(--White, #fff);
            
            font-size: 30px;
            font-style: normal;
            font-weight: 500;
            align-self: stretch;
          }
          .content {
            color: rgba(255, 255, 255, 0.68);
            
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            width: 50%;
            align-self: stretch;
          }
        }
        .rightdiv-footer {
          width: 40%;
          height: auto;
          position: absolute;
          margin: auto;
          margin-top: 263px;
        }
      }
      .partsecond {
        width: 80%;
        margin-top: 150px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 60px;
        .leftdiv-footer {
          width: 60%;
        }
        .rightdiv-footer {
          display: flex;
          grid-area: 10px;
          justify-content: center;
          align-items: center;
          margin: auto;
          .apple-img {
            display: flex;
            padding: 8px 10px;
            flex-direction: column;
            align-items: flex-start;
            height: 37px;
            width: 100px;
            border-radius: 13px;
            border: 2px solid var(--White, #fff);
            background: #000;
            margin: auto;
            margin-right: 20px;
            img {
              width: 100%;
            }
          }
          .google-img {
            display: flex;
            padding: 8px 10px;
            flex-direction: column;
            align-items: flex-start;
            height: 37px;
            width: 100px;
            border-radius: 13px;
            border: 2px solid var(--White, #fff);
            background: #000;
            margin: auto;
            margin-right: 20px;
            img {
              width: 100%;
            }
          }
        }
      }
    }
    .middle-part {
     flex-direction: column;
     background-color: #111111;
      .social-part {
        width: 100%;
        height: auto;
        gap: 35px;
        align-items: flex-start;
        .logo-company-name {
          display: flex;
          align-items: center;
          gap: 20px;
          .company-logo {
            width: 48px;
            flex-shrink: 0;
            img {
              width: 100%;
            }
          }
          .company-name {
            color: var(--White, #fff);
            font-family: Lexend;
            font-size: 35px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
        .text {
          font-size: 22px;
          width: 80%;
        }
        .social-media-icons {
          display: flex;
          gap: 20px;
          .icons {
            width: 44px;
            height: 44px;
            flex-shrink: 0;
            border-radius: 9px;
            background: var(--Text, #252525);
            img {
              width: 100%;
            }
          }
        }
      }
      .links-divs {
        display: flex;
        width: 60%;
        flex-direction: column;
        align-items: flex-start;
        gap: 35px;
        margin-top: 35px;
  
        .important-links1 {
          display: none;
        }
        .important-links2 {
          display: flex;
          flex-direction: column;
          // justify-content: baseline;
          gap: 20px;
          .heading {
            display: none;
          }
          .multiple-links {
            display: flex;
            flex-direction: column;
            gap: 20px;
            font-size: 18px;
            justify-content: center;
            .links {
              color: var(--White, #fff);
              
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
            }
          }
        }
        .important-links3 {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 20px;
          margin-right: -99px;
          .heading {
            display: none;
          }
          .multiple-links {
            display: flex;
            flex-direction: column;
            gap: 20px;
            font-size: 16px;
            justify-content: center;
            .links {
              color: var(--White, #fff);
              
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
            }
          }
        }
      }
    }
    .lower-part {
      background: #282828;
      display: flex;
      justify-content: space-between;
      padding: 20px 20px;
      .left-lower-part {
        color: var(--White, #fff);
        font-family: "DM Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        margin-left: 10px;
      }
      .right-lower-part {
        display: inline-flex;
        justify-content: flex-end;
        align-items: center;
        gap: 25px;
        margin-right: 10px;
        .footer-links {
          color: var(--Main-Subtext, #909090);
          
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
        }
      }
    }
  }
}

// Extra large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {

}

// Extra large screens (1200px and up)
// @media (min-width: 1200px) and (max-width: 1600px) {
// }