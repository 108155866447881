// HomePage.scss

:root {
  --Blue-primary: #1f5ee6;
  --Black: #202020;
  --Gray-light: #9fa9b9;
  --White: #ffffff;
}

.main-home {
  margin-bottom: 6px;
  .section-one {
    display: flex;
    flex-direction: column;
    align-items: center;

    .section-one-inner {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 20px;

      .left-div,
      .right-div {
        width: 27%;
        display: flex;
        align-items: end;
        margin-bottom: -113px;
        img {
          width: 100%;
        }
      }

      .center-div {
        width: 90%; // Adjust width as necessary
        display: flex;
        gap: 30px;
        flex-direction: column;

        .center-top {
          text-align: center;
          font-size: 50px;
          font-weight: 500;
          .center-top-word {
            color: var(--Blue-primary);
            text-transform: capitalize;
          }
          span {
            color: var(--Black);
            text-transform: capitalize;
          }
        }

        .center-middle {
          display: flex;
          justify-content: space-around;
          margin: 20px 0;
          .tick-item {
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;

            .tick-icon {
              width: 18px;
              height: 18px;
            }

            span {
              color: var(--Gray-light);
              justify-content: center;
              display: flex;
              font-size: 18px;
              font-weight: 400;
              text-transform: capitalize;
            }
          }
        }

        .center-bottom {
          display: flex;
          width: 75%;
          justify-content: center;
          align-items: center;
          padding-bottom: 50px;
          margin: auto;

          .bottom-item-1 {
            margin-top: 15px;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 30%;
            text-align: center;

            .generate-bill,
            .generate-qr,
            .manage-inventory {
              font-size: 20px;
              font-weight: 700;
              width: 80%;
              text-align: left;
            }
            .generate-bill {
              text-align: left;
            }
            .generate-qr {
              text-align: center;
            }
            .manage-inventory {
              text-align: right;
            }

            .bottom-image {
              width: 100%; // Ensure images are responsive and equal in size
              height: auto;
            }
          }
          .bottom-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 30%;
            text-align: center;
            margin-top: 12px;
            .generate-bill,
            .generate-qr,
            .manage-inventory {
              font-size: 20px;
              font-weight: 700;
              width: 80%;
              text-align: left;
            }
            .generate-bill {
              text-align: left;
            }
            .generate-qr {
              text-align: center;
            }
            .manage-inventory {
              text-align: right;
            }

            .bottom-image {
              width: 100%; // Ensure images are responsive and equal in size
              height: auto;
            }
          }
        }
      }
    }
  }

  .section-two {
    background: linear-gradient(270deg, #edf3ff 0%, #f7f3ff 100%);
    padding: 50px 0px;
    position: relative;
    .section-two-content {
      color: var(--Text, #252525);

      font-size: 25px;
      font-style: normal;
      font-weight: 500;
      width: 40%;
      margin-left: 100px;
    }
  }

  .section-three {
    background: var(--White, #fff);
    padding: 80px 0px;
    .section-three-main {
      .problems-heading {
        color: var(--Text, #252525);

        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 0%;
        text-align: center;
        margin: auto;
      }
      .problems-points {
        display: flex;
        padding: 44px 35px;
        align-items: center;
        gap: 10px;
        flex: 1 0;
        justify-content: center;
        width: 85%;
        margin: auto;
        .first-point {
          display: flex;
          padding: 20px 30px;
          justify-content: center;
          align-items: center;
          gap: 15px;
          /* flex: 1 0; */
          border-radius: 26px;
          border: 1px solid #d0dffe;
          background: linear-gradient(
            180deg,
            #fff 0%,
            rgba(233, 240, 255, 0.59) 100%
          );
          .point-img {
            width: 80px;
            height: 80px;
            display: flex;
            img {
              width: 100%;
            }
          }
          .point-text {
            display: flex;
            flex-direction: column;
            gap: 15px;
            .point-heading {
              color: var(--Primary, #1f5ee6);

              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 0%; /* 0px */
              text-transform: capitalize;
            }
            .point-sub-heading {
              color: var(--Text, #252525);

              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 134%;
              text-transform: capitalize;
            }
          }
        }
        .second-point {
          display: flex;
          padding: 20px 30px;
          justify-content: center;
          align-items: center;
          gap: 15px;
          border-radius: 26px;
          border: 1px solid #d0dffe;
          background: linear-gradient(
            180deg,
            #fff 0%,
            rgba(233, 240, 255, 0.59) 100%
          );
          .point-img {
            width: 80px;
            height: 80px;
            display: flex;
            img {
              width: 100%;
            }
          }
          .point-text {
            display: flex;
            flex-direction: column;
            gap: 15px;
            .point-heading {
              color: var(--Primary, #1f5ee6);

              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 0%; /* 0px */
              text-transform: capitalize;
            }
            .point-sub-heading {
              color: var(--Text, #252525);

              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 134%;
              text-transform: capitalize;
            }
          }
        }
        .third-point {
          display: flex;
          padding: 20px 30px;
          justify-content: center;
          align-items: center;
          gap: 15px;
          border-radius: 26px;
          border: 1px solid #d0dffe;
          background: linear-gradient(
            180deg,
            #fff 0%,
            rgba(233, 240, 255, 0.59) 100%
          );
          .point-img {
            width: 80px;
            height: 80px;
            display: flex;
            img {
              width: 100%;
            }
          }
          .point-text {
            display: flex;
            flex-direction: column;
            gap: 15px;
            .point-heading {
              color: var(--Primary, #1f5ee6);

              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 0%; /* 0px */
              text-transform: capitalize;
            }
            .point-sub-heading {
              color: var(--Text, #252525);

              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 134%; /* 21.44px */
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }

  .section-four {
    background-color: #00133c;

    .upper-div {
      display: flex;
      gap: 30px;
      justify-content: space-between;
      align-items: flex-start;
      padding: 20px;
      width: 70%;
      margin: auto;
      padding-top: 50px;

      .upper-left-div {
        width: 55%;
        display: flex;
        flex-direction: column;
        gap: 17px;

        .upper-left-item1 {
          display: flex;
          gap: 15px;
          flex-shrink: 0;
          padding: 10px 10px;
          border-radius: 23px;
          background: var(--White, #fff);
          justify-content: center;
          align-items: center;

          .left-image {
            width: 60px;
            height: 60px;
            img {
              width: 100%;
              height: 100%;
            }
          }

          .right-text {
            display: flex;
            flex-direction: column;

            .upper-text {
              font-size: 18px;
              font-weight: bold;
              color: var(--Blue-primary);
            }

            .lower-text {
              font-size: 15px;
              color: var(--Gray-light);
            }
          }
        }
        .upper-left-item2 {
          display: flex;
          gap: 15px;
          flex-shrink: 0;
          padding: 10px 10px;
          border-radius: 23px;
          background: #142f6b;
          justify-content: center;
          align-items: center;

          .left-image {
            width: 60px;
            height: 60px;
            img {
              width: 100%;
              height: 100%;
            }
          }

          .right-text {
            display: flex;
            flex-direction: column;

            .upper-text {
              font-size: 18px;
              font-weight: bold;
              color: #ffffff;
            }

            .lower-text {
              font-size: 15px;
              color: var(--Gray-light);
            }
          }
        }
        .upper-left-item3 {
          display: flex;
          gap: 15px;
          flex-shrink: 0;
          padding: 10px 10px;
          border-radius: 23px;
          background: #142f6b;
          justify-content: center;
          align-items: center;

          .left-image {
            width: 60px;
            height: 60px;
            img {
              width: 100%;
              height: 100%;
            }
          }

          .right-text {
            display: flex;
            flex-direction: column;

            .upper-text {
              font-size: 18px;
              font-weight: bold;
              color: #ffffff;
            }

            .lower-text {
              font-size: 15px;
              color: var(--Gray-light);
            }
          }
        }

        .top-item {
          color: var(--White, #fff);

          font-size: 23px;
          font-style: normal;
          font-weight: 500;
          width: 70%;
        }
      }

      .upper-right-div {
        width: 45%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 100%;
        }
      }
    }

    .lower-div {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;
      gap: 40px;
      margin-left: 130px;

      .lower-left-div {
        width: 47%;
        img {
          width: 100%;
        }
      }

      .lower-right-div {
        width: 45%;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .lower-right-item {
          display: flex;
          flex-direction: column;
          gap: 15px;

          .upper-text {
            color: var(--White, #fff);

            font-size: 21px;
            font-style: normal;
            font-weight: 500;
            width: 70%;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .lower-text {
            color: #657aa9;

            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            /* line-height: 136.166%; */
            width: 70%;
            text-transform: capitalize;
          }
        }
      }
    }
  }

  .section-five {
    margin: auto;
    padding: 80px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: #f7faff;
    .steps-text {
      color: var(--Text, #252525);

      font-size: 26px;
      font-style: normal;
      font-weight: 500;
      margin-left: 4.5rem;
    }
    .number-steps {
      color: var(--Primary, #1f5ee6);

      font-size: 56.382px;
      font-style: normal;
      margin-left: 4.5rem;
      font-weight: 500;
    }
    .one-two-three-steps {
      display: flex;
      justify-content: space-evenly;
      .step-one {
        width: 272px;
        height: 272px;
        flex-shrink: 0;
        border-radius: 20px;
        background: #1d1d1d;
        display: flex;
        .left-step-one {
          padding: 15px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 15px;
          position: absolute;
          .step-count {
            border-radius: 3px;
            background: var(--White, #fff);
            display: inline-flex;
            padding: 2px 5px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: var(--Text, #252525);
            font-size: 17px;
            font-style: normal;
            font-weight: 400;
            max-width: 15%;
          }
          .instruction {
            color: var(--White, #fff);

            font-size: 24px;
            font-style: normal;
            font-weight: 300;
            width: 50%;
            line-height: 30px;
          }
          .logo-image {
            width: 72px;
            margin-top: 49px;
            img {
              width: 100%;
            }
          }
        }
        .right-step-one {
          margin: auto;
          .human-img {
            width: 160px;
            position: relative;
            margin-left: 112px;
            margin-top: 29px;
            img {
              width: 100%;
            }
          }
        }
      }
      .step-two {
        width: 272px;
        padding: 15px;
        height: 242px;
        flex-shrink: 0;
        border-radius: 20px;
        background: red;
        background-image: url("../../images/CrushingPaper.jpg");
        background-color: var(--Primary, #1f5ee6);
        background-size: cover;
        background-blend-mode: multiply;
        gap: 15px;
        display: flex;
        flex-direction: column;
        .step-count {
          border-radius: 3px;
          background: var(--White, #fff);
          display: inline-flex;
          padding: 2px 5px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: var(--Text, #252525);
          font-size: 17px;
          font-style: normal;
          font-weight: 400;
          max-width: 19%;
        }
        .instruction {
          color: var(--White, #fff);

          font-size: 21px;
          font-style: normal;
          font-weight: 300;
          width: 61%;
          line-height: 30px;
        }
        .logo-image {
          width: 60px;
          // margin-top: 17px;
          img {
            width: 100%;
          }
        }
      }
      .step-three {
        width: 272px;
        padding: 15px;
        height: 242px;
        flex-shrink: 0;
        border-radius: 20px;
        background-color: var(--White, #ffffff);
        gap: 15px;
        display: flex;
        flex-direction: column;
        .step-count {
          border-radius: 3px;
          background: var(--Primary, #1f5ee6);
          display: inline-flex;
          padding: 2px 5px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: var(--White, #ffffff);
          font-size: 17px;
          font-style: normal;
          font-weight: 400;
          max-width: 19%;
        }
        .instruction {
          color: var(--Black, #202020);

          font-size: 22px;
          font-style: normal;
          font-weight: 300;
          width: 61%;
          line-height: 30px;
        }
        .logo-image {
          width: 60px;
          // margin-top: 17px;
          img {
            width: 100%;
          }
        }
        .right-step-one {
          margin: auto;
          .Stars-img {
            width: 100px;
            position: relative;
            margin-left: 186px;
            margin-top: -30px;
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .section-six {
    background: #e9eff9;
    padding-top: 60px;
    padding-bottom: 60px;
    .section-six-main {
      .heading-section-six {
        color: var(--Text, #252525);

        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        text-align: center;
        margin: auto;
        margin-bottom: 30px;
      }
      .reviews-wrapper {
        overflow: hidden;
        width: 100%;
        position: relative;
        display: flex;
        gap: 20px;
        margin: auto;
        .reviews {
          display: flex;
          gap: 20px;
          animation: scroll 20s linear infinite;
          .card-reviews {
            width: 242px;
            gap: 0px;
            display: flex;
            flex-direction: column;
            border-radius: 23px;
            background-image: url("../../images/review.svg");
            background-size: cover;
            background-position: center;
            padding: 15px;
            color: white;
            position: relative;
            .upper {
              display: flex;
              gap: 10px;

              .left-img {
                width: 40px;
                img {
                  width: 100%;
                }
              }

              .right-content {
                .name {
                  color: #000;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 100%;
                }

                .bussiness-type {
                  color: #909090;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  max-width: 75%;
                }
              }
            }
            .line {
              img {
                width: 100%;
              }
            }
            .lower {
              color: #909090;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
            }

            .review-stars {
              width: 128px;
              margin-top: 40px;
              img {
                width: 100%;
              }
            }
          }
        }
        @keyframes scroll {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-100%);
          }
        }
        /* Shadows on both sides */
        &::before,
        &::after {
          content: "";
          position: absolute;
          top: 0;
          width: 30px;
          height: 100%;
          z-index: 1;
          pointer-events: none;
        }

        /* Left side shadow */
        &::before {
          left: 0;
          background: linear-gradient(
            to right,
            rgba(214, 214, 214, 0.3),
            transparent
          );
        }

        /* Right side shadow */
        &::after {
          right: 0;
          background: linear-gradient(
            to left,
            rgba(214, 214, 214, 0.3),
            transparent
          );
        }
      }
    }
  }
}

// Small devices (portrait phones, less than 576px)
@media (min-width: 320px) and (max-width: 475.98px) {
  .main-home {
    margin-bottom: 6px;
    .section-one {
      display: flex;
      flex-direction: column;
      align-items: center;

      .section-one-inner {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 20px;

        .left-div,
        .right-div {
          width: 27%;
          display: flex;
          align-items: end;
          margin-bottom: -113px;
          img {
            width: 100%;
          }
        }

        .center-div {
          width: 90%;
          display: flex;
          gap: 30px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;

          .center-top {
            font-size: 2.5rem;
            text-align: center;
            font-weight: 600;

            .center-top-word {
              color: var(--Blue-primary);

              text-transform: capitalize;
            }
            span {
              color: var(--Black);

              text-transform: capitalize;
            }
          }

          .center-middle {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin: 20px 0;
            gap: 8px;
            margin: auto;

            .tick-item {
              text-align: center;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 10px;

              .tick-icon {
                width: 100%;
              }

              span {
                color: var(--Gray-light);
                justify-content: center;
                display: flex;
                font-size: 0.875rem;
                font-weight: 400;
                text-transform: capitalize;
                text-align: left;
              }
            }
          }

          .center-bottom {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            padding-bottom: 50px;
            margin: auto;
            .bottom-item-1 {
              gap: 20px;
            }
            .bottom-item-1,
            .bottom-item {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 100%; // Adjust width for equal distribution
              text-align: center;

              .generate-bill,
              .generate-qr,
              .manage-inventory {
                font-size: 0.875rem;
                font-weight: 700;
                width: auto;
                text-align: left;
              }
              .generate-bill {
                text-align: left;
              }
              .generate-qr {
                text-align: center;
              }
              .manage-inventory {
                text-align: right;
                padding-bottom: 15px;
              }

              .bottom-image {
                width: 100%; // Ensure images are responsive and equal in size
                height: auto;
              }
            }
          }
        }
      }
    }

    .section-two {
      background: linear-gradient(270deg, #edf3ff 0%, #f7f3ff 100%);
      padding: 50px 0px;
      position: relative;
      .section-two-content {
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 500;
        margin-left: 25px;
        width: 70%;
      }
    }

    .section-three {
      background: var(--White, #fff);
      padding: 40px 0px;
      .section-three-main {
        width: 90%;
        text-align: left;
        margin: auto;
        .problems-heading {
          color: var(--Text, #252525);
          font-size: 2rem;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          text-align: left;
          margin-left: 5px;
        }
        .problems-points {
          display: flex;
          padding: 0px;
          flex-direction: column;
          align-items: center;
          gap: 10px;
          flex: 1 0;
          justify-content: center;
          width: 90%;
          margin: auto;
          margin-top: 30px;
          margin-left: 15px;
          .first-point {
            display: flex;
            padding: 20px 30px;
            justify-content: center;
            align-items: center;
            gap: 15px;
            /* flex: 1 0; */
            border-radius: 26px;
            border: 1px solid #d0dffe;
            background: linear-gradient(
              180deg,
              #fff 0%,
              rgba(233, 240, 255, 0.59) 100%
            );
            .point-img {
              width: 6rem;
              height: auto;
              img {
                width: 100%;
              }
            }
            .point-text {
              display: flex;
              flex-direction: column;
              gap: 5px;
              .point-heading {
                color: var(--Primary, #1f5ee6);
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; /* 0px */
                text-transform: capitalize;
              }
              .point-sub-heading {
                color: var(--Text, #252525);
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 600;
                line-height: 1.1rem;
                text-transform: capitalize;
              }
            }
          }
          .second-point {
            display: flex;
            padding: 20px 20px;
            justify-content: center;
            align-items: center;
            gap: 15px;
            border-radius: 26px;
            border: 1px solid #d0dffe;
            background: linear-gradient(
              180deg,
              #fff 0%,
              rgba(233, 240, 255, 0.59) 100%
            );
            .point-img {
              width: 6rem;
              height: auto;
              img {
                width: 100%;
              }
            }
            .point-text {
              display: flex;
              flex-direction: column;
              gap: 5px;
              .point-heading {
                color: var(--Primary, #1f5ee6);
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; /* 0px */
                text-transform: capitalize;
              }
              .point-sub-heading {
                color: var(--Text, #252525);
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 600;
                line-height: 1.1rem;
                text-transform: capitalize;
              }
            }
          }
          .third-point {
            display: flex;
            padding: 20px 15px;
            justify-content: center;
            align-items: center;
            gap: 15px;
            border-radius: 26px;
            border: 1px solid #d0dffe;
            background: linear-gradient(
              180deg,
              #fff 0%,
              rgba(233, 240, 255, 0.59) 100%
            );
            .point-img {
              width: 6rem;
              height: auto;
              img {
                width: 100%;
              }
            }
            .point-text {
              display: flex;
              flex-direction: column;
              gap: 5px;
              .point-heading {
                color: var(--Primary, #1f5ee6);
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; /* 0px */
                text-transform: capitalize;
              }
              .point-sub-heading {
                color: var(--Text, #252525);
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 600;
                line-height: 1.1rem;
                text-transform: capitalize;
              }
            }
          }
        }
      }
    }

    .section-four {
      background-color: #00133c;

      .upper-div {
        display: flex;
        flex-direction: column-reverse;
        gap: 30px;
        justify-content: space-between;
        align-items: flex-start;
        padding: 20px;
        width: auto;
        margin: auto;
        padding-top: 50px;

        .upper-left-div {
          align-items: center;
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 20px;
          text-align: center;

          .upper-left-item1 {
            max-width: 289px;
            height: 175px;
            display: flex;
            gap: 15px;
            flex-shrink: 0;
            padding: 20px 20px;
            border-radius: 23px;
            background: var(--White, #ffffff);
            justify-content: center;
            align-items: center;

            .left-image {
              max-width: 48%;
              height: auto;
              margin-top: -70px;
              width: 138px;
              img {
                width: 100%;
                height: auto;
              }
            }

            .right-text {
              display: flex;
              flex-direction: column;

              .upper-text {
                font-size: 1.2rem;
                text-align: left;
                font-weight: 600;
                color: #000000;
                width: 100%;
                max-width: 330px;
              }

              .lower-text {
                margin-left: -66px;
                margin-top: 10px;
                font-size: 1rem;
                color: var(--Gray-light);
                text-align: left;
              }
            }
          }
          .upper-left-item2 {
            display: flex;
            gap: 15px;
            flex-shrink: 0;
            padding: 20px 20px;
            border-radius: 23px;
            background: #142f6b;
            justify-content: center;
            align-items: center;
            max-width: 289px;
            height: 175px;
            .left-image {
              max-width: 48%;
              height: auto;
              margin-top: -70px;
              width: 138px;
              img {
                width: 100%;
                height: auto;
              }
            }

            .right-text {
              display: flex;
              flex-direction: column;

              .upper-text {
                font-size: 1.2rem;
                text-align: left;
                font-weight: 600;
                color: var(--White, #ffffff);
              }

              .lower-text {
                margin-left: -66px;
                margin-top: 10px;
                font-size: 1rem;
                color: var(--Gray-light);
                text-align: left;
              }
            }
          }
          .upper-left-item3 {
            display: flex;
            gap: 15px;
            flex-shrink: 0;
            padding: 20px 20px;
            border-radius: 23px;
            background: #142f6b;
            justify-content: center;
            align-items: center;
            max-width: 289px;
            height: 175px;
            .left-image {
              max-width: 48%;
              height: auto;
              margin-top: -70px;
              width: 138px;
              img {
                width: 100%;
                height: auto;
              }
            }

            .right-text {
              display: flex;
              flex-direction: column;

              .upper-text {
                font-size: 1.2rem;
                text-align: left;
                font-weight: 600;
                color: var(--White, #ffffff);
              }

              .lower-text {
                margin-left: -66px;
                margin-top: 10px;
                font-size: 1rem;
                color: var(--Gray-light);
                text-align: left;
              }
            }
          }

          .top-item {
            font-size: 2.25rem;
            width: 100%;
            text-align: center;
            margin-bottom: 30px;
          }
        }

        .upper-right-div {
          max-width: 345px;
          height: 310px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: auto;
          margin: auto;
          img {
            width: 100%;
          }
        }
      }

      .lower-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        gap: 40px;
        margin-top: 30px;
        margin-left: 0px;
        padding-bottom: 80px;
        .lower-left-div {
          width: 90%;
          img {
            width: 100%;
          }
        }

        .lower-right-div {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 20px;
          align-items: center;

          .lower-right-item {
            display: flex;
            flex-direction: column;
            gap: 15px;
            align-items: center;

            .upper-text {
              color: var(--White, #fff);
              font-size: 1.8rem;
              font-style: normal;
              font-weight: 500;
              max-width: 100%;
              width: 280px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
            }

            .lower-text {
              font-size: 0.875rem;
              text-align: center;
              width: 70%;
            }
          }
        }
      }
    }

    .section-five {
      margin: auto;
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: flex-start;
      background: #f7faff;
      max-width: 289px;
      .steps-text {
        color: var(--Text, #252525);
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 600;
        margin-left: 0px;
      }
      .number-steps {
        color: var(--Primary, #1f5ee6);
        font-size: 2.25rem;
        font-style: normal;
        margin-left: 0px;
        font-weight: 600;
      }
      .one-two-three-steps {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        gap: 25px;
        width: 100%;
        max-width: 289px;
        .step-one {
          width: 100%;
          height: auto;
          flex-shrink: 0;
          border-radius: 20px;
          background: #1d1d1d;
          display: flex;
          .left-step-one {
            padding: 15px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 15px;
            position: absolute;
            .step-count {
              border-radius: 3px;
              background: var(--White, #fff);
              display: inline-flex;
              padding: 2px 0px;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              color: var(--Text, #252525);
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              max-width: 18%;
            }
            .instruction {
              color: var(--White, #fff);
              font-size: 32px;
              font-style: normal;
              font-weight: 300;
              width: 59%;
              line-height: 43.7px;
            }
            .logo-image {
              width: 72px;
              margin-top: 0px;
              margin-left: 41px;
              img {
                width: 100%;
              }
            }
          }
          .right-step-one {
            margin: auto;
            .human-img {
              width: 149px;
              position: relative;
              margin-left: 140px;
              margin-top: 58px;
              display: flex;
              img {
                width: 100%;
              }
            }
          }
        }
        .step-two {
          width: 100%;
          height: auto;
          padding: 0px;
          .step-count {
            margin-left: 15px;
            margin-top: 15px;
            border-radius: 3px;
            background: var(--White, #fff);
            display: inline-flex;
            padding: 2px 5px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: var(--Text, #252525);

            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            max-width: 18%;
          }
          .instruction {
            color: var(--White, #fff);
            font-size: 32px;
            font-style: normal;
            font-weight: 400;
            width: 85%;
            margin-left: 15px;
            line-height: 43.57px;
          }
          .logo-image {
            width: 78px;
            margin-left: 15px;
            margin-bottom: 10px;
            img {
              width: 100%;
            }
          }
        }
        .step-three {
          width: 100%;
          padding: 0px;
          height: 228px;
          flex-shrink: 0;
          border-radius: 20px;
          background-color: var(--White, #ffffff);
          gap: 15px;
          display: flex;
          flex-direction: column;
          margin-bottom: 60px;
          .step-count {
            margin-top: 15px;
            margin-left: 15px;
            border-radius: 3px;
            background: var(--Blue-primary, #1f5ee6);
            display: inline-flex;
            padding: 2px 5px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: var(--White, #ffffff);

            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            max-width: 18%;
          }
          .instruction {
            color: var(--Black, #202020);
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            width: 80%;
            line-height: 42.7px;
            margin-left: 15px;
          }
          .logo-image {
            width: 70px;
            margin-left: 15px;
            img {
              width: 100%;
            }
          }
          .right-step-one {
            margin: auto;
            .Stars-img {
              width: 90px;
              position: relative;
              margin-left: 183px;
              margin-top: -106px;
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }

    .section-six {
      background: #e9eff9;
      padding-top: 0px;
      padding-bottom: 60px;
      .section-six-main {
        .heading-section-six {
          color: var(--Text, #252525);
          font-size: 1.4375rem;
          font-style: normal;
          font-weight: 600;
          padding: 20px;
          text-align: left;
          margin: auto;
          margin-bottom: 30px;
        }
        // .reviews-wrapper {
        //   overflow: hidden;
        // width: 100%;
        // position: relative;
        // display: flex;
        // gap: 20px;
        // margin: auto;
        //   .reviews {
        //     display: flex;
        //     gap: 20px;
        //     justify-content: space-around;
        //     flex-direction: column;
        //     .card-reviews {
        //       width: 80%;
        //       gap: 0px;
        //       display: flex;
        //       flex-direction: column;
        //       border-radius: 23px;
        //       background-image: url("../../images/review.svg"); /* Add the background image here */
        //       background-size: cover; /* Ensures the background covers the whole card */
        //       background-position: center; /* Center the image in the background */
        //       padding: 15px;
        //       color: white; /* Ensure text is readable on the background image */
        //       position: relative; /* Positioning for the content over the image */

        //       .upper {
        //         display: flex;
        //         gap: 10px;

        //         .left-img {
        //           width: 80px;
        //           img {
        //             width: 100%;
        //           }
        //         }

        //         .right-content {
        //           display: flex;
        //           flex-direction: column;
        //           justify-content: center;
        //           text-align: left;
        //           gap: 5px;
        //           .name {
        //             color: #000;

        //             font-size: 1.6rem;
        //             font-style: normal;
        //             font-weight: 600;
        //             line-height: 100%;
        //           }

        //           .bussiness-type {
        //             color: #909090; /* Light grey for readability */

        //             font-size: 1.2rem;
        //             font-style: normal;
        //             font-weight: 400;
        //           }
        //         }
        //       }
        //       .line {
        //         img {
        //           width: 100%;
        //         }
        //       }
        //       .lower {
        //         color: #909090; /* Adjust text color for readability */

        //         font-size: 1.2rem;
        //         font-style: normal;
        //         font-weight: 400;
        //       }

        //       .review-stars {
        //         width: 200px;
        //         margin-top: 70px;
        //         img {
        //           width: 60%;
        //         }
        //       }
        //     }
        //   }
        // }
      }
    }
  }
}

// Small devices (portrait phones, less than 576px)
@media (min-width: 476px) and (max-width: 575.98px) {
  .main-home {
    margin-bottom: 6px;
    .section-one {
      display: flex;
      flex-direction: column;
      align-items: center;

      .section-one-inner {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 20px;

        .left-div,
        .right-div {
          width: 27%;
          display: flex;
          align-items: end;
          margin-bottom: -113px;
          img {
            width: 100%;
          }
        }

        .center-div {
          width: 90%;
          display: flex;
          gap: 30px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;

          .center-top {
            font-size: 3rem;
            text-align: center;
            font-weight: 600;

            .center-top-word {
              color: var(--Blue-primary);

              text-transform: capitalize;
            }
            span {
              color: var(--Black);

              text-transform: capitalize;
            }
          }

          .center-middle {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin: 20px 0;
            gap: 8px;
            margin: auto;

            .tick-item {
              text-align: center;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 10px;

              .tick-icon {
                width: 100%;
              }

              span {
                color: var(--Gray-light);
                justify-content: center;
                display: flex;

                font-size: 1.6rem;
                font-weight: 400;
                text-transform: capitalize;
                text-align: left;
              }
            }
          }

          .center-bottom {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            padding-bottom: 50px;
            margin: auto;

            .bottom-item1 {
              margin-top: 15px;
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 100%;
              text-align: center;
            }
            .bottom-item-1,
            .bottom-item {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 100%; // Adjust width for equal distribution
              text-align: center;

              .generate-bill,
              .generate-qr,
              .manage-inventory {
                font-size: 20px;
                font-weight: 700;
                width: 80%;
                text-align: left;
              }
              .generate-bill {
                text-align: left;
                font-size: 16px;
              }
              .generate-qr {
                text-align: center;
                font-size: 16px;
              }
              .manage-inventory {
                text-align: right;
                font-size: 16px;
                padding-bottom: 15px;
              }

              .bottom-image {
                width: 100%; // Ensure images are responsive and equal in size
                height: auto;
              }
            }
          }
        }
      }
    }

    .section-two {
      background: linear-gradient(270deg, #edf3ff 0%, #f7f3ff 100%);
      padding: 50px 0px;
      position: relative;
      .section-two-content {
        color: var(--Text, #252525);

        font-size: 2.2rem;
        font-style: normal;
        font-weight: 500;
        margin-left: 40px;
        width: 70%;
      }
    }

    .section-three {
      background: var(--White, #fff);
      padding: 40px 0px;
      .section-three-main {
        width: 90%;
        text-align: left;
        margin: auto;
        .problems-heading {
          color: var(--Text, #252525);

          font-size: 3.3rem;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          text-align: left;
          margin-left: 15px;
          // width: 80%;
        }
        .problems-points {
          display: flex;
          padding: 0px;
          flex-direction: column;
          align-items: center;
          gap: 10px;
          flex: 1 0;
          justify-content: center;
          width: 90%;
          margin: auto;
          margin-top: 30px;
          margin-left: 15px;
          .first-point {
            display: flex;
            padding: 20px 30px;
            justify-content: center;
            align-items: center;
            gap: 15px;
            /* flex: 1 0; */
            border-radius: 26px;
            border: 1px solid #d0dffe;
            background: linear-gradient(
              180deg,
              #fff 0%,
              rgba(233, 240, 255, 0.59) 100%
            );
            .point-img {
              width: 6rem;
              height: auto;
              img {
                width: 100%;
              }
            }
            .point-text {
              display: flex;
              flex-direction: column;
              gap: 5px;
              .point-heading {
                color: var(--Primary, #1f5ee6);

                font-size: 1.4rem;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; /* 0px */
                text-transform: capitalize;
              }
              .point-sub-heading {
                color: var(--Text, #252525);

                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: 134%;
                text-transform: capitalize;
              }
            }
          }
          .second-point {
            display: flex;
            padding: 20px 30px;
            justify-content: center;
            align-items: center;
            gap: 15px;
            border-radius: 26px;
            border: 1px solid #d0dffe;
            background: linear-gradient(
              180deg,
              #fff 0%,
              rgba(233, 240, 255, 0.59) 100%
            );
            .point-img {
              width: 6rem;
              height: auto;
              img {
                width: 100%;
              }
            }
            .point-text {
              display: flex;
              flex-direction: column;
              gap: 5px;
              .point-heading {
                color: var(--Primary, #1f5ee6);

                font-size: 1.4rem;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; /* 0px */
                text-transform: capitalize;
              }
              .point-sub-heading {
                color: var(--Text, #252525);

                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: 134%;
                text-transform: capitalize;
              }
            }
          }
          .third-point {
            display: flex;
            padding: 20px 30px;
            justify-content: center;
            align-items: center;
            gap: 15px;
            border-radius: 26px;
            border: 1px solid #d0dffe;
            background: linear-gradient(
              180deg,
              #fff 0%,
              rgba(233, 240, 255, 0.59) 100%
            );
            .point-img {
              width: 6rem;
              height: auto;
              img {
                width: 100%;
              }
            }
            .point-text {
              display: flex;
              flex-direction: column;
              gap: 5px;
              .point-heading {
                color: var(--Primary, #1f5ee6);

                font-size: 1.4rem;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; /* 0px */
                text-transform: capitalize;
              }
              .point-sub-heading {
                color: var(--Text, #252525);

                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: 134%; /* 21.44px */
                text-transform: capitalize;
              }
            }
          }
        }
      }
    }

    .section-four {
      background-color: #00133c;

      .upper-div {
        display: flex;
        flex-direction: column;
        gap: 30px;
        justify-content: space-between;
        align-items: flex-start;
        padding: 20px;
        width: 70%;
        margin: auto;
        padding-top: 50px;

        .upper-left-div {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 16px;
          text-align: center;

          .upper-left-item1 {
            display: flex;
            gap: 15px;
            flex-shrink: 0;
            padding: 20px 20px;
            border-radius: 23px;
            background: var(--White, #ffffff);
            justify-content: center;
            align-items: center;

            .left-image {
              width: 150px;
              height: 100px;
              margin-top: -95px;
              img {
                width: 100%;
                height: 100%;
              }
            }

            .right-text {
              display: flex;
              flex-direction: column;

              .upper-text {
                font-size: 1.6rem;
                text-align: left;
                font-weight: bold;
                color: #000000;
              }

              .lower-text {
                margin-left: -66px;
                margin-top: 10px;
                font-size: 1.4rem;
                color: var(--Gray-light);
                text-align: left;
              }
            }
          }
          .upper-left-item2 {
            display: flex;
            gap: 15px;
            flex-shrink: 0;
            padding: 20px 20px;
            border-radius: 23px;
            background: #142f6b;
            justify-content: center;
            align-items: center;

            .left-image {
              width: 150px;
              height: 100px;
              margin-top: -95px;
              img {
                width: 100%;
                height: 100%;
              }
            }

            .right-text {
              display: flex;
              flex-direction: column;

              .upper-text {
                font-size: 1.6rem;
                text-align: left;
                font-weight: bold;
                color: var(--White, #ffffff);
              }

              .lower-text {
                margin-left: -66px;
                margin-top: 10px;
                font-size: 1.4rem;
                color: var(--Gray-light);
                text-align: left;
              }
            }
          }
          .upper-left-item3 {
            display: flex;
            gap: 15px;
            flex-shrink: 0;
            padding: 20px 20px;
            border-radius: 23px;
            background: #142f6b;
            justify-content: center;
            align-items: center;

            .left-image {
              width: 150px;
              height: 100px;
              margin-top: -95px;
              img {
                width: 100%;
                height: 100%;
              }
            }

            .right-text {
              display: flex;
              flex-direction: column;

              .upper-text {
                font-size: 1.6rem;
                text-align: left;
                font-weight: bold;
                color: var(--White, #ffffff);
              }

              .lower-text {
                margin-left: -66px;
                margin-top: 10px;
                font-size: 1.4rem;
                color: var(--Gray-light);
                text-align: left;
              }
            }
          }

          .top-item {
            font-size: 44px;
            width: 100%;
            text-align: center;
            margin-bottom: 30px;
          }
        }

        .upper-right-div {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 100%;
          }
        }
      }

      .lower-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        gap: 40px;
        margin-left: 0px;
        padding-bottom: 80px;
        .lower-left-div {
          width: 90%;
          img {
            width: 100%;
          }
        }

        .lower-right-div {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 20px;
          align-items: center;

          .lower-right-item {
            display: flex;
            flex-direction: column;
            gap: 15px;
            align-items: center;

            .upper-text {
              color: var(--White, #fff);
              font-size: 32px;
              font-style: normal;
              font-weight: 500;
              width: 79%;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
            }

            .lower-text {
              font-size: 18px;
              text-align: center;
              width: 80%;
            }
          }
        }
      }
    }

    .section-five {
      margin: auto;
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      background: #f7faff;
      max-width: 316px;
      .steps-text {
        color: var(--Text, #252525);
        font-size: 1.3rem;
        font-style: normal;
        font-weight: 600;
        margin-left: 0px;
      }
      .number-steps {
        color: var(--Primary, #1f5ee6);
        font-size: 36px;
        font-style: normal;
        margin-left: -80px;
      }
      .one-two-three-steps {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        gap: 25px;
        .step-one {
          width: 316px;
          height: 272px;
          flex-shrink: 0;
          border-radius: 20px;
          background: #1d1d1d;
          display: flex;
          .left-step-one {
            padding: 15px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 15px;
            position: absolute;
            .step-count {
              border-radius: 3px;
              background: var(--White, #fff);
              display: inline-flex;
              padding: 2px 0px;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              color: var(--Text, #252525);

              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              max-width: 18%;
            }
            .instruction {
              color: var(--White, #fff);
              font-size: 32px;
              font-style: normal;
              font-weight: 300;
              width: 50%;
              line-height: 40px;
            }
            .logo-image {
              width: 72px;
              margin-top: 2px;
              margin-left: 44px;
              img {
                width: 100%;
              }
            }
          }
          .right-step-one {
            margin: auto;
            .human-img {
              width: 160px;
              position: relative;
              margin-left: 156px;
              margin-top: 29px;
              img {
                width: 100%;
              }
            }
          }
        }
        .step-two {
          width: 286px;
          .step-count {
            border-radius: 3px;
            background: var(--White, #fff);
            display: inline-flex;
            padding: 2px 5px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: var(--Text, #252525);

            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            max-width: 18%;
          }
          .instruction {
            color: var(--White, #fff);
            font-size: 32px;
            font-style: normal;
            font-weight: 400;
            width: 82.96%;
            line-height: 38px;
          }
          .logo-image {
            width: 70px;
            // margin-top: 17px;
            img {
              width: 100%;
            }
          }
        }
        .step-three {
          width: 286px;
          padding: 15px;
          height: 242px;
          flex-shrink: 0;
          border-radius: 20px;
          background-color: var(--White, #ffffff);
          gap: 15px;
          display: flex;
          flex-direction: column;
          margin-bottom: 60px;
          .step-count {
            border-radius: 3px;
            background: var(--Blue-primary, #1f5ee6);
            display: inline-flex;
            padding: 2px 5px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: var(--White, #ffffff);

            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            max-width: 18%;
          }
          .instruction {
            color: var(--Black, #202020);
            font-size: 32px;
            font-style: normal;
            font-weight: 400;
            width: 80%;
            line-height: 41px;
          }
          .logo-image {
            width: 50px;
            // margin-top: 17px;
            img {
              width: 100%;
            }
          }
          .right-step-one {
            margin: auto;
            .Stars-img {
              width: 90px;
              position: relative;
              margin-left: 183px;
              margin-top: -90px;
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }

    .section-six {
      background: #e9eff9;
      padding-top: 60px;
      padding-bottom: 60px;
      .section-six-main {
        .heading-section-six {
          color: var(--Text, #252525);
          font-size: 23px;
          font-style: normal;
          font-weight: 600;
          width: 65%;
          text-align: left;
          margin: auto;
          margin-bottom: 30px;
        }
        // .reviews-wrapper {
        //   overflow: hidden;
        // width: 100%;
        // position: relative;
        // display: flex;
        // gap: 20px;
        // margin: auto;
        //   .reviews {
        //     display: flex;
        //     gap: 20px;
        //     justify-content: space-around;
        //     flex-direction: column;
        //     .card-reviews {
        //       width: 58%;
        //       gap: 0px;
        //       display: flex;
        //       flex-direction: column;
        //       border-radius: 23px;
        //       background-image: url("../../images/review.svg"); /* Add the background image here */
        //       background-size: cover; /* Ensures the background covers the whole card */
        //       background-position: center; /* Center the image in the background */
        //       padding: 15px;
        //       color: white; /* Ensure text is readable on the background image */
        //       position: relative; /* Positioning for the content over the image */

        //       .upper {
        //         display: flex;
        //         gap: 10px;

        //         .left-img {
        //           width: 80px;
        //           img {
        //             width: 100%;
        //           }
        //         }

        //         .right-content {
        //           display: flex;
        //           flex-direction: column;
        //           justify-content: center;
        //           text-align: left;
        //           gap: 5px;
        //           .name {
        //             color: #000;

        //             font-size: 1.6rem;
        //             font-style: normal;
        //             font-weight: 600;
        //             line-height: 100%;
        //           }

        //           .bussiness-type {
        //             color: #909090; /* Light grey for readability */

        //             font-size: 1.2rem;
        //             font-style: normal;
        //             font-weight: 400;
        //           }
        //         }
        //       }
        //       .line {
        //         img {
        //           width: 100%;
        //         }
        //       }
        //       .lower {
        //         color: #909090; /* Adjust text color for readability */

        //         font-size: 1.2rem;
        //         font-style: normal;
        //         font-weight: 400;
        //       }

        //       .review-stars {
        //         width: 200px;
        //         margin-top: 70px;
        //         img {
        //           width: 60%;
        //         }
        //       }
        //     }
        //   }
        // }
      }
    }
  }
}

// Medium devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  .main-home {
    margin-bottom: 6px;
    .section-one {
      display: flex;
      flex-direction: column;
      align-items: center;

      .section-one-inner {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 20px;

        .left-div,
        .right-div {
          width: 27%;
          display: flex;
          align-items: end;
          margin-bottom: -113px;
          img {
            width: 100%;
          }
        }

        .center-div {
          width: 90%;
          display: flex;
          gap: 30px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;

          .center-top {
            font-size: 3.6rem;
            text-align: center;
            font-weight: 600;

            .center-top-word {
              color: var(--Blue-primary);

              text-transform: capitalize;
            }
            span {
              color: var(--Black);

              text-transform: capitalize;
            }
          }

          .center-middle {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin: 20px 0;
            gap: 8px;
            margin: auto;

            .tick-item {
              text-align: center;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 10px;

              .tick-icon {
                width: 100%;
              }

              span {
                color: var(--Gray-light);
                justify-content: center;
                display: flex;

                font-size: 1.6rem;
                font-weight: 400;
                text-transform: capitalize;
                text-align: left;
              }
            }
          }

          .center-bottom {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            padding-bottom: 50px;
            margin: auto;
            .bottom-item1 {
              margin-top: 15px;
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 100%;
              text-align: center;
            }
            .bottom-item-1,
            .bottom-item {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 100%; // Adjust width for equal distribution
              text-align: center;

              .generate-bill,
              .generate-qr,
              .manage-inventory {
                font-size: 20px;
                font-weight: 700;
                width: 80%;
                text-align: left;
              }
              .generate-bill {
                text-align: left;
                font-size: 16px;
              }
              .generate-qr {
                text-align: center;
                font-size: 16px;
              }
              .manage-inventory {
                text-align: right;
                font-size: 16px;
                padding-bottom: 15px;
              }

              .bottom-image {
                width: 100%; // Ensure images are responsive and equal in size
                height: auto;
              }
            }
          }
        }
      }
    }

    .section-two {
      background: linear-gradient(270deg, #edf3ff 0%, #f7f3ff 100%);
      padding: 50px 0px;
      position: relative;
      .section-two-content {
        color: var(--Text, #252525);

        font-size: 2.2rem;
        font-style: normal;
        font-weight: 500;
        margin-left: 40px;
        width: 70%;
      }
    }

    .section-three {
      background: var(--White, #fff);
      padding: 40px 0px;
      .section-three-main {
        width: 90%;
        text-align: left;
        margin: auto;
        .problems-heading {
          color: var(--Text, #252525);

          font-size: 3.3rem;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          text-align: left;
          margin-left: 15px;
          // width: 80%;
        }
        .problems-points {
          display: flex;
          padding: 0px;
          flex-direction: column;
          align-items: center;
          gap: 10px;
          flex: 1 0;
          justify-content: center;
          width: 90%;
          margin: auto;
          margin-top: 30px;
          margin-left: 15px;
          .first-point {
            display: flex;
            padding: 20px 30px;
            justify-content: center;
            align-items: center;
            gap: 15px;
            /* flex: 1 0; */
            border-radius: 26px;
            border: 1px solid #d0dffe;
            background: linear-gradient(
              180deg,
              #fff 0%,
              rgba(233, 240, 255, 0.59) 100%
            );
            .point-img {
              width: 6rem;
              height: auto;
              img {
                width: 100%;
              }
            }
            .point-text {
              display: flex;
              flex-direction: column;
              gap: 5px;
              .point-heading {
                color: var(--Primary, #1f5ee6);

                font-size: 1.4rem;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; /* 0px */
                text-transform: capitalize;
              }
              .point-sub-heading {
                color: var(--Text, #252525);

                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: 134%;
                text-transform: capitalize;
              }
            }
          }
          .second-point {
            display: flex;
            padding: 20px 30px;
            justify-content: center;
            align-items: center;
            gap: 15px;
            border-radius: 26px;
            border: 1px solid #d0dffe;
            background: linear-gradient(
              180deg,
              #fff 0%,
              rgba(233, 240, 255, 0.59) 100%
            );
            .point-img {
              width: 6rem;
              height: auto;
              img {
                width: 100%;
              }
            }
            .point-text {
              display: flex;
              flex-direction: column;
              gap: 5px;
              .point-heading {
                color: var(--Primary, #1f5ee6);

                font-size: 1.4rem;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; /* 0px */
                text-transform: capitalize;
              }
              .point-sub-heading {
                color: var(--Text, #252525);

                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: 134%;
                text-transform: capitalize;
              }
            }
          }
          .third-point {
            display: flex;
            padding: 20px 30px;
            justify-content: center;
            align-items: center;
            gap: 15px;
            border-radius: 26px;
            border: 1px solid #d0dffe;
            background: linear-gradient(
              180deg,
              #fff 0%,
              rgba(233, 240, 255, 0.59) 100%
            );
            .point-img {
              width: 6rem;
              height: auto;
              img {
                width: 100%;
              }
            }
            .point-text {
              display: flex;
              flex-direction: column;
              gap: 5px;
              .point-heading {
                color: var(--Primary, #1f5ee6);

                font-size: 1.4rem;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; /* 0px */
                text-transform: capitalize;
              }
              .point-sub-heading {
                color: var(--Text, #252525);

                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: 134%; /* 21.44px */
                text-transform: capitalize;
              }
            }
          }
        }
      }
    }

    .section-four {
      background-color: #00133c;

      .upper-div {
        display: flex;
        flex-direction: column;
        gap: 30px;
        justify-content: space-between;
        align-items: flex-start;
        padding: 20px;
        width: 70%;
        margin: auto;
        padding-top: 50px;

        .upper-left-div {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 16px;
          text-align: center;

          .upper-left-item1 {
            display: flex;
            gap: 15px;
            flex-shrink: 0;
            padding: 20px 20px;
            border-radius: 23px;
            background: var(--White, #ffffff);
            justify-content: center;
            align-items: center;

            .left-image {
              width: 150px;
              height: 100px;
              margin-top: -95px;
              img {
                width: 100%;
                height: 100%;
              }
            }

            .right-text {
              display: flex;
              flex-direction: column;

              .upper-text {
                font-size: 1.6rem;
                text-align: left;
                font-weight: bold;
                color: #000000;
              }

              .lower-text {
                margin-left: -66px;
                margin-top: 10px;
                font-size: 1.4rem;
                color: var(--Gray-light);
                text-align: left;
              }
            }
          }
          .upper-left-item2 {
            display: flex;
            gap: 15px;
            flex-shrink: 0;
            padding: 20px 20px;
            border-radius: 23px;
            background: #142f6b;
            justify-content: center;
            align-items: center;

            .left-image {
              width: 150px;
              height: 100px;
              margin-top: -95px;
              img {
                width: 100%;
                height: 100%;
              }
            }

            .right-text {
              display: flex;
              flex-direction: column;

              .upper-text {
                font-size: 1.6rem;
                text-align: left;
                font-weight: bold;
                color: var(--White, #ffffff);
              }

              .lower-text {
                margin-left: -66px;
                margin-top: 10px;
                font-size: 1.4rem;
                color: var(--Gray-light);
                text-align: left;
              }
            }
          }
          .upper-left-item3 {
            display: flex;
            gap: 15px;
            flex-shrink: 0;
            padding: 20px 20px;
            border-radius: 23px;
            background: #142f6b;
            justify-content: center;
            align-items: center;

            .left-image {
              width: 150px;
              height: 100px;
              margin-top: -95px;
              img {
                width: 100%;
                height: 100%;
              }
            }

            .right-text {
              display: flex;
              flex-direction: column;

              .upper-text {
                font-size: 1.6rem;
                text-align: left;
                font-weight: bold;
                color: var(--White, #ffffff);
              }

              .lower-text {
                margin-left: -66px;
                margin-top: 10px;
                font-size: 1.4rem;
                color: var(--Gray-light);
                text-align: left;
              }
            }
          }

          .top-item {
            font-size: 44px;
            width: 100%;
            text-align: center;
            margin-bottom: 30px;
          }
        }

        .upper-right-div {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 100%;
          }
        }
      }

      .lower-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        gap: 40px;
        margin-left: 0px;
        padding-bottom: 80px;
        .lower-left-div {
          width: 90%;
          img {
            width: 100%;
          }
        }

        .lower-right-div {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 20px;
          align-items: center;

          .lower-right-item {
            display: flex;
            flex-direction: column;
            gap: 15px;
            align-items: center;

            .upper-text {
              color: var(--White, #fff);

              font-size: 2.9rem;
              font-style: normal;
              font-weight: 500;
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
            }

            .lower-text {
              font-size: 1.8rem;
              text-align: center;
              width: 100%;
            }
          }
        }
      }
    }

    .section-five {
      margin: auto;
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      background: #f7faff;
      .steps-text {
        color: var(--Text, #252525);

        font-size: 1.8rem;
        font-style: normal;
        font-weight: 600;
        margin-left: 0px;
      }
      .number-steps {
        color: var(--Primary, #1f5ee6);

        font-size: 2.9rem;
        font-style: normal;
        margin-left: 0px;
        font-weight: 600;
      }
      .one-two-three-steps {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        gap: 25px;
        .step-one {
          width: 289px;
          height: 272px;
          flex-shrink: 0;
          border-radius: 20px;
          background: #1d1d1d;
          display: flex;
          .left-step-one {
            padding: 15px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 15px;
            position: absolute;
            .step-count {
              border-radius: 3px;
              background: var(--White, #fff);
              display: inline-flex;
              padding: 2px 0px;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              color: var(--Text, #252525);

              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              max-width: 18%;
            }
            .instruction {
              color: var(--White, #fff);

              font-size: 24px;
              font-style: normal;
              font-weight: 300;
              width: 63%;
              line-height: 30px;
            }
            .logo-image {
              width: 72px;
              margin-top: 27px;
              img {
                width: 100%;
              }
            }
          }
          .right-step-one {
            margin: auto;
            .human-img {
              width: 160px;
              position: relative;
              margin-left: 112px;
              margin-top: 29px;
              img {
                width: 100%;
              }
            }
          }
        }
        .step-two {
          width: 260px;
          .step-count {
            border-radius: 3px;
            background: var(--White, #fff);
            display: inline-flex;
            padding: 2px 5px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: var(--Text, #252525);

            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            max-width: 18%;
          }
          .instruction {
            color: var(--White, #fff);

            font-size: 27px;
            font-style: normal;
            font-weight: 300;
            width: 80%;
            line-height: 30px;
          }
          .logo-image {
            width: 60px;
            // margin-top: 17px;
            img {
              width: 100%;
            }
          }
        }
        .step-three {
          width: 272px;
          padding: 15px;
          height: 242px;
          flex-shrink: 0;
          border-radius: 20px;
          background-color: var(--White, #ffffff);
          gap: 15px;
          display: flex;
          flex-direction: column;
          margin-bottom: 60px;
          .step-count {
            border-radius: 3px;
            background: var(--Blue-primary, #1f5ee6);
            display: inline-flex;
            padding: 2px 5px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: var(--White, #ffffff);

            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            max-width: 18%;
          }
          .instruction {
            color: var(--Black, #202020);

            font-size: 25px;
            font-style: normal;
            font-weight: 500;
            width: 72%;
            line-height: 30px;
          }
          .logo-image {
            width: 50px;
            // margin-top: 17px;
            img {
              width: 100%;
            }
          }
          .right-step-one {
            margin: auto;
            .Stars-img {
              width: 90px;
              position: relative;
              margin-left: 183px;
              margin-top: -90px;
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }

    .section-six {
      background: #e9eff9;
      padding-top: 60px;
      padding-bottom: 60px;
      .section-six-main {
        .heading-section-six {
          color: var(--Text, #252525);

          font-size: 2.3rem;
          font-style: normal;
          font-weight: 600;
          width: 90%;
          text-align: left;
          margin: auto;
          margin-bottom: 30px;
        }
        // .reviews-wrapper {
        //   overflow: hidden;
        // width: 100%;
        // position: relative;
        // display: flex;
        // gap: 20px;
        // margin: auto;
        //   .reviews {
        //     display: flex;
        //     gap: 20px;
        //     justify-content: space-around;
        //     flex-direction: column;
        //     .card-reviews {
        //       width: 58%;
        //       gap: 0px;
        //       display: flex;
        //       flex-direction: column;
        //       border-radius: 23px;
        //       background-image: url("../../images/review.svg"); /* Add the background image here */
        //       background-size: cover; /* Ensures the background covers the whole card */
        //       background-position: center; /* Center the image in the background */
        //       padding: 15px;
        //       color: white; /* Ensure text is readable on the background image */
        //       position: relative; /* Positioning for the content over the image */

        //       .upper {
        //         display: flex;
        //         gap: 10px;

        //         .left-img {
        //           width: 80px;
        //           img {
        //             width: 100%;
        //           }
        //         }

        //         .right-content {
        //           display: flex;
        //           flex-direction: column;
        //           justify-content: center;
        //           text-align: left;
        //           gap: 5px;
        //           .name {
        //             color: #000;

        //             font-size: 1.6rem;
        //             font-style: normal;
        //             font-weight: 600;
        //             line-height: 100%;
        //           }

        //           .bussiness-type {
        //             color: #909090; /* Light grey for readability */

        //             font-size: 1.2rem;
        //             font-style: normal;
        //             font-weight: 400;
        //           }
        //         }
        //       }
        //       .line {
        //         img {
        //           width: 100%;
        //         }
        //       }
        //       .lower {
        //         color: #909090; /* Adjust text color for readability */

        //         font-size: 1.2rem;
        //         font-style: normal;
        //         font-weight: 400;
        //       }

        //       .review-stars {
        //         width: 200px;
        //         margin-top: 70px;
        //         img {
        //           width: 60%;
        //         }
        //       }
        //     }
        //   }
        // }
      }
    }
  }
}

// Large devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  .main-home {
    margin-bottom: 6px;
    .section-one {
      display: flex;
      flex-direction: column;
      align-items: center;

      .section-one-inner {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 20px;

        .left-div,
        .right-div {
          width: 27%;
          display: flex;
          align-items: end;
          margin-bottom: -113px;
          img {
            width: 100%;
          }
        }

        .center-div {
          width: 90%;
          display: flex;
          gap: 30px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;

          .center-top {
            font-size: 3.6rem;
            text-align: center;
            font-weight: 600;

            .center-top-word {
              color: var(--Blue-primary);

              font-size: 76.02px;
              font-weight: 600;
            }
            span {
              color: var(--Black);

              font-size: 76.02px;
              font-weight: 600;
            }
          }

          .center-middle {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin: 20px 0;
            gap: 8px;
            margin: auto;

            .tick-item {
              text-align: center;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 10px;

              .tick-icon {
                width: 100%;
              }

              span {
                color: var(--Gray-light);
                justify-content: center;
                display: flex;

                font-size: 1.6rem;
                font-weight: 400;
                text-transform: capitalize;
                text-align: left;
              }
            }
          }

          .center-bottom {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            padding-bottom: 50px;
            margin: auto;

            .bottom-item1 {
              margin-top: 15px;
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 100%;
              text-align: center;
            }
            .bottom-item-1,
            .bottom-item {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 100%; // Adjust width for equal distribution
              text-align: center;

              .generate-bill,
              .generate-qr,
              .manage-inventory {
                font-size: 20px;
                font-weight: 700;
                width: 80%;
                text-align: left;
              }
              .generate-bill {
                text-align: left;
                font-size: 16px;
              }
              .generate-qr {
                text-align: center;
                font-size: 16px;
              }
              .manage-inventory {
                text-align: right;
                font-size: 16px;
                padding-bottom: 15px;
              }

              .bottom-image {
                width: 100%; // Ensure images are responsive and equal in size
                height: auto;
              }
            }
          }
        }
      }
    }

    .section-two {
      background: linear-gradient(270deg, #edf3ff 0%, #f7f3ff 100%);
      padding: 50px 0px;
      position: relative;
      .section-two-content {
        color: var(--Text, #252525);

        font-size: 2.2rem;
        font-style: normal;
        font-weight: 500;
        margin-left: 40px;
        width: 70%;
      }
    }

    .section-three {
      background: var(--White, #fff);
      padding: 40px 0px;
      .section-three-main {
        width: 90%;
        text-align: left;
        margin: auto;
        .problems-heading {
          color: var(--Text, #252525);

          font-size: 3.3rem;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          text-align: left;
          margin-left: 15px;
          // width: 80%;
        }
        .problems-points {
          display: flex;
          padding: 0px;
          flex-direction: column;
          align-items: center;
          gap: 10px;
          flex: 1 0;
          justify-content: center;
          width: 90%;
          margin: auto;
          margin-top: 30px;
          margin-left: 15px;
          .first-point {
            display: flex;
            padding: 20px 30px;
            justify-content: center;
            align-items: center;
            gap: 15px;
            /* flex: 1 0; */
            border-radius: 26px;
            border: 1px solid #d0dffe;
            background: linear-gradient(
              180deg,
              #fff 0%,
              rgba(233, 240, 255, 0.59) 100%
            );
            .point-img {
              width: 6rem;
              height: auto;
              img {
                width: 100%;
              }
            }
            .point-text {
              display: flex;
              flex-direction: column;
              gap: 5px;
              .point-heading {
                color: var(--Primary, #1f5ee6);

                font-size: 1.4rem;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; /* 0px */
                text-transform: capitalize;
              }
              .point-sub-heading {
                color: var(--Text, #252525);

                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: 134%;
                text-transform: capitalize;
              }
            }
          }
          .second-point {
            display: flex;
            padding: 20px 30px;
            justify-content: center;
            align-items: center;
            gap: 15px;
            border-radius: 26px;
            border: 1px solid #d0dffe;
            background: linear-gradient(
              180deg,
              #fff 0%,
              rgba(233, 240, 255, 0.59) 100%
            );
            .point-img {
              width: 6rem;
              height: auto;
              img {
                width: 100%;
              }
            }
            .point-text {
              display: flex;
              flex-direction: column;
              gap: 5px;
              .point-heading {
                color: var(--Primary, #1f5ee6);

                font-size: 1.4rem;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; /* 0px */
                text-transform: capitalize;
              }
              .point-sub-heading {
                color: var(--Text, #252525);

                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: 134%;
                text-transform: capitalize;
              }
            }
          }
          .third-point {
            display: flex;
            padding: 20px 30px;
            justify-content: center;
            align-items: center;
            gap: 15px;
            border-radius: 26px;
            border: 1px solid #d0dffe;
            background: linear-gradient(
              180deg,
              #fff 0%,
              rgba(233, 240, 255, 0.59) 100%
            );
            .point-img {
              width: 6rem;
              height: auto;
              img {
                width: 100%;
              }
            }
            .point-text {
              display: flex;
              flex-direction: column;
              gap: 5px;
              .point-heading {
                color: var(--Primary, #1f5ee6);

                font-size: 1.4rem;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; /* 0px */
                text-transform: capitalize;
              }
              .point-sub-heading {
                color: var(--Text, #252525);

                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: 134%; /* 21.44px */
                text-transform: capitalize;
              }
            }
          }
        }
      }
    }

    .section-four {
      background-color: #00133c;

      .upper-div {
        display: flex;
        flex-direction: column;
        gap: 30px;
        justify-content: space-between;
        align-items: flex-start;
        padding: 20px;
        width: 70%;
        margin: auto;
        padding-top: 50px;

        .upper-left-div {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 16px;
          text-align: center;

          .upper-left-item1 {
            display: flex;
            gap: 15px;
            flex-shrink: 0;
            padding: 90px 20px;
            border-radius: 23px;
            background: var(--White, #ffffff);
            justify-content: center;
            align-items: center;

            .left-image {
              width: 150px;
              height: 100px;
              margin-top: -95px;
              img {
                width: 100%;
                height: auto;
              }
            }

            .right-text {
              display: flex;
              flex-direction: column;

              .upper-text {
                font-size: 2.2rem;
                text-align: left;
                font-weight: bold;
                color: #000000;
              }

              .lower-text {
                margin-left: -66px;
                margin-top: 10px;
                font-size: 2rem;
                color: var(--Gray-light);
                text-align: left;
              }
            }
          }
          .upper-left-item2 {
            display: flex;
            gap: 15px;
            flex-shrink: 0;
            padding: 90px 20px;
            border-radius: 23px;
            background: #142f6b;
            justify-content: center;
            align-items: center;

            .left-image {
              width: 150px;
              height: 100px;
              margin-top: -95px;
              img {
                width: 100%;
                height: auto;
              }
            }

            .right-text {
              display: flex;
              flex-direction: column;

              .upper-text {
                font-size: 2.2rem;
                text-align: left;
                font-weight: bold;
                color: var(--White, #ffffff);
              }

              .lower-text {
                margin-left: -66px;
                margin-top: 10px;
                font-size: 2rem;
                color: var(--Gray-light);
                text-align: left;
              }
            }
          }
          .upper-left-item3 {
            display: flex;
            gap: 15px;
            flex-shrink: 0;
            padding: 90px 20px;
            border-radius: 23px;
            background: #142f6b;
            justify-content: center;
            align-items: center;

            .left-image {
              width: 150px;
              height: 100px;
              margin-top: -95px;
              img {
                width: 100%;
                height: auto;
              }
            }

            .right-text {
              display: flex;
              flex-direction: column;

              .upper-text {
                font-size: 2.2rem;
                text-align: left;
                font-weight: bold;
                color: var(--White, #ffffff);
              }

              .lower-text {
                margin-left: -66px;
                margin-top: 10px;
                font-size: 2rem;
                color: var(--Gray-light);
                text-align: left;
              }
            }
          }

          .top-item {
            font-size: 44px;
            width: 100%;
            text-align: center;
            margin-bottom: 30px;
          }
        }

        .upper-right-div {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 100%;
          }
        }
      }

      .lower-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        gap: 40px;
        margin-left: 0px;
        padding-bottom: 80px;
        .lower-left-div {
          width: 90%;
          img {
            width: 100%;
          }
        }

        .lower-right-div {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 20px;
          align-items: center;

          .lower-right-item {
            display: flex;
            flex-direction: column;
            gap: 15px;
            align-items: center;

            .upper-text {
              color: var(--White, #fff);

              font-size: 2.9rem;
              font-style: normal;
              font-weight: 500;
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
            }

            .lower-text {
              font-size: 1.8rem;
              text-align: center;
              width: 100%;
            }
          }
        }
      }
    }

    .section-five {
      margin: auto;
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      background: #f7faff;
      .steps-text {
        color: var(--Text, #252525);

        font-size: 1.8rem;
        font-style: normal;
        font-weight: 600;
        margin-left: 0px;
      }
      .number-steps {
        color: var(--Primary, #1f5ee6);

        font-size: 2.9rem;
        font-style: normal;
        margin-left: 0px;
        font-weight: 600;
      }
      .one-two-three-steps {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        gap: 25px;
        .step-one {
          width: 289px;
          height: 272px;
          flex-shrink: 0;
          border-radius: 20px;
          background: #1d1d1d;
          display: flex;
          .left-step-one {
            padding: 15px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 15px;
            position: absolute;
            .step-count {
              border-radius: 3px;
              background: var(--White, #fff);
              display: inline-flex;
              padding: 2px 0px;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              color: var(--Text, #252525);

              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              max-width: 18%;
            }
            .instruction {
              color: var(--White, #fff);

              font-size: 24px;
              font-style: normal;
              font-weight: 300;
              width: 63%;
              line-height: 30px;
            }
            .logo-image {
              width: 72px;
              margin-top: 27px;
              img {
                width: 100%;
              }
            }
          }
          .right-step-one {
            margin: auto;
            .human-img {
              width: 160px;
              position: relative;
              margin-left: 112px;
              margin-top: 29px;
              img {
                width: 100%;
              }
            }
          }
        }
        .step-two {
          width: 260px;
          .step-count {
            border-radius: 3px;
            background: var(--White, #fff);
            display: inline-flex;
            padding: 2px 5px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: var(--Text, #252525);

            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            max-width: 18%;
          }
          .instruction {
            color: var(--White, #fff);

            font-size: 27px;
            font-style: normal;
            font-weight: 300;
            width: 80%;
            line-height: 30px;
          }
          .logo-image {
            width: 60px;
            // margin-top: 17px;
            img {
              width: 100%;
            }
          }
        }
        .step-three {
          width: 272px;
          padding: 15px;
          height: 242px;
          flex-shrink: 0;
          border-radius: 20px;
          background-color: var(--White, #ffffff);
          gap: 15px;
          display: flex;
          flex-direction: column;
          margin-bottom: 60px;
          .step-count {
            border-radius: 3px;
            background: var(--Blue-primary, #1f5ee6);
            display: inline-flex;
            padding: 2px 5px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: var(--White, #ffffff);

            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            max-width: 18%;
          }
          .instruction {
            color: var(--Black, #202020);

            font-size: 25px;
            font-style: normal;
            font-weight: 500;
            width: 72%;
            line-height: 30px;
          }
          .logo-image {
            width: 50px;
            // margin-top: 17px;
            img {
              width: 100%;
            }
          }
          .right-step-one {
            margin: auto;
            .Stars-img {
              width: 90px;
              position: relative;
              margin-left: 183px;
              margin-top: -90px;
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }

    .section-six {
      background: #e9eff9;
      padding-top: 60px;
      padding-bottom: 60px;
      .section-six-main {
        .heading-section-six {
          color: var(--Text, #252525);

          font-size: 2.3rem;
          font-style: normal;
          font-weight: 600;
          width: 90%;
          text-align: left;
          margin: auto;
          margin-bottom: 30px;
        }
        // .reviews-wrapper {
        //   overflow: hidden;
        // width: 100%;
        // position: relative;
        // display: flex;
        // gap: 20px;
        // margin: auto;
        //   .reviews {
        //     display: flex;
        //     gap: 20px;
        //     justify-content: space-around;
        //     flex-direction: column;
        //     .card-reviews {
        //       width: 45%;
        //       gap: 0px;
        //       display: flex;
        //       flex-direction: column;
        //       border-radius: 23px;
        //       background-image: url("../../images/review.svg"); /* Add the background image here */
        //       background-size: cover; /* Ensures the background covers the whole card */
        //       background-position: center; /* Center the image in the background */
        //       padding: 15px;
        //       color: white; /* Ensure text is readable on the background image */
        //       position: relative; /* Positioning for the content over the image */

        //       .upper {
        //         display: flex;
        //         gap: 10px;

        //         .left-img {
        //           width: 80px;
        //           img {
        //             width: 100%;
        //           }
        //         }

        //         .right-content {
        //           display: flex;
        //           flex-direction: column;
        //           justify-content: center;
        //           text-align: left;
        //           gap: 5px;
        //           .name {
        //             color: #000;

        //             font-size: 1.6rem;
        //             font-style: normal;
        //             font-weight: 600;
        //             line-height: 100%;
        //           }

        //           .bussiness-type {
        //             color: #909090; /* Light grey for readability */

        //             font-size: 1.2rem;
        //             font-style: normal;
        //             font-weight: 400;
        //           }
        //         }
        //       }
        //       .line {
        //         img {
        //           width: 100%;
        //         }
        //       }
        //       .lower {
        //         color: #909090; /* Adjust text color for readability */

        //         font-size: 1.2rem;
        //         font-style: normal;
        //         font-weight: 400;
        //       }

        //       .review-stars {
        //         width: 200px;
        //         margin-top: 70px;
        //         img {
        //           width: 60%;
        //         }
        //       }
        //     }
        //   }
        // }
      }
    }
  }
}

// Extra large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .main-home {
    margin-bottom: 6px;
    .section-one {
      display: flex;
      flex-direction: column;
      align-items: center;

      .section-one-inner {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 20px;

        .left-div,
        .right-div {
          width: 27%;
          display: flex;
          align-items: end;
          margin-bottom: -113px;
          img {
            width: 100%;
          }
        }

        .center-div {
          width: 90%;
          display: flex;
          gap: 30px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;

          .center-top {
            font-size: 3.6rem;
            text-align: center;
            font-weight: 600;

            .center-top-word {
              color: var(--Blue-primary);

              text-transform: capitalize;
            }
            span {
              color: var(--Black);

              text-transform: capitalize;
            }
          }

          .center-middle {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin: 20px 0;
            gap: 8px;
            margin: auto;

            .tick-item {
              text-align: center;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 10px;

              .tick-icon {
                width: 100%;
              }

              span {
                color: var(--Gray-light);
                justify-content: center;
                display: flex;

                font-size: 1.6rem;
                font-weight: 400;
                text-transform: capitalize;
                text-align: left;
              }
            }
          }

          .center-bottom {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            padding-bottom: 50px;
            margin: auto;
            .bottom-item1 {
              margin-top: 15px;
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 100%;
              text-align: center;
            }
            .bottom-item-1,
            .bottom-item {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 100%; // Adjust width for equal distribution
              text-align: center;

              .generate-bill,
              .generate-qr,
              .manage-inventory {
                font-size: 20px;
                font-weight: 700;
                width: 80%;
                text-align: left;
              }
              .generate-bill {
                text-align: left;
                font-size: 16px;
              }
              .generate-qr {
                text-align: center;
                font-size: 16px;
              }
              .manage-inventory {
                text-align: right;
                font-size: 16px;
                padding-bottom: 15px;
              }

              .bottom-image {
                width: 100%; // Ensure images are responsive and equal in size
                height: auto;
              }
            }
          }
        }
      }
    }

    .section-two {
      background: linear-gradient(270deg, #edf3ff 0%, #f7f3ff 100%);
      padding: 50px 0px;
      position: relative;
      .section-two-content {
        color: var(--Text, #252525);

        font-size: 2.2rem;
        font-style: normal;
        font-weight: 500;
        margin-left: 40px;
        width: 70%;
      }
    }

    .section-three {
      background: var(--White, #fff);
      padding: 40px 0px;
      .section-three-main {
        width: 90%;
        text-align: left;
        margin: auto;
        .problems-heading {
          color: var(--Text, #252525);

          font-size: 3.3rem;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          text-align: left;
          margin-left: 15px;
          // width: 80%;
        }
        .problems-points {
          display: flex;
          padding: 0px;
          flex-direction: column;
          align-items: center;
          gap: 10px;
          flex: 1 0;
          justify-content: center;
          width: 90%;
          margin: auto;
          margin-top: 30px;
          margin-left: 15px;
          .first-point {
            display: flex;
            padding: 20px 30px;
            justify-content: center;
            align-items: center;
            gap: 15px;
            /* flex: 1 0; */
            border-radius: 26px;
            border: 1px solid #d0dffe;
            background: linear-gradient(
              180deg,
              #fff 0%,
              rgba(233, 240, 255, 0.59) 100%
            );
            .point-img {
              width: 6rem;
              height: auto;
              img {
                width: 100%;
              }
            }
            .point-text {
              display: flex;
              flex-direction: column;
              gap: 5px;
              .point-heading {
                color: var(--Primary, #1f5ee6);

                font-size: 1.4rem;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; /* 0px */
                text-transform: capitalize;
              }
              .point-sub-heading {
                color: var(--Text, #252525);

                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: 134%;
                text-transform: capitalize;
              }
            }
          }
          .second-point {
            display: flex;
            padding: 20px 30px;
            justify-content: center;
            align-items: center;
            gap: 15px;
            border-radius: 26px;
            border: 1px solid #d0dffe;
            background: linear-gradient(
              180deg,
              #fff 0%,
              rgba(233, 240, 255, 0.59) 100%
            );
            .point-img {
              width: 6rem;
              height: auto;
              img {
                width: 100%;
              }
            }
            .point-text {
              display: flex;
              flex-direction: column;
              gap: 5px;
              .point-heading {
                color: var(--Primary, #1f5ee6);

                font-size: 1.4rem;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; /* 0px */
                text-transform: capitalize;
              }
              .point-sub-heading {
                color: var(--Text, #252525);

                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: 134%;
                text-transform: capitalize;
              }
            }
          }
          .third-point {
            display: flex;
            padding: 20px 30px;
            justify-content: center;
            align-items: center;
            gap: 15px;
            border-radius: 26px;
            border: 1px solid #d0dffe;
            background: linear-gradient(
              180deg,
              #fff 0%,
              rgba(233, 240, 255, 0.59) 100%
            );
            .point-img {
              width: 6rem;
              height: auto;
              img {
                width: 100%;
              }
            }
            .point-text {
              display: flex;
              flex-direction: column;
              gap: 5px;
              .point-heading {
                color: var(--Primary, #1f5ee6);

                font-size: 1.4rem;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; /* 0px */
                text-transform: capitalize;
              }
              .point-sub-heading {
                color: var(--Text, #252525);

                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: 134%; /* 21.44px */
                text-transform: capitalize;
              }
            }
          }
        }
      }
    }

    .section-four {
      background-color: #00133c;

      .upper-div {
        display: flex;
        flex-direction: column-reverse;
        gap: 30px;
        justify-content: space-between;
        align-items: flex-start;
        padding: 20px;
        width: 70%;
        margin: auto;
        padding-top: 50px;

        .upper-left-div {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 16px;
          text-align: center;

          .upper-left-item1 {
            display: flex;
            gap: 15px;
            flex-shrink: 0;
            padding: 90px 20px;
            border-radius: 23px;
            background: var(--White, #ffffff);
            justify-content: center;
            align-items: center;

            .left-image {
              width: 150px;
              height: 100px;
              margin-top: -95px;
              img {
                width: 100%;
                height: auto;
              }
            }

            .right-text {
              display: flex;
              flex-direction: column;

              .upper-text {
                font-size: 2.2rem;
                text-align: left;
                font-weight: bold;
                color: #000000;
              }

              .lower-text {
                margin-left: -66px;
                margin-top: 10px;
                font-size: 2rem;
                color: var(--Gray-light);
                text-align: left;
              }
            }
          }
          .upper-left-item2 {
            display: flex;
            gap: 15px;
            flex-shrink: 0;
            padding: 90px 20px;
            border-radius: 23px;
            background: #142f6b;
            justify-content: center;
            align-items: center;

            .left-image {
              width: 150px;
              height: 100px;
              margin-top: -95px;
              img {
                width: 100%;
                height: auto;
              }
            }

            .right-text {
              display: flex;
              flex-direction: column;

              .upper-text {
                font-size: 2.2rem;
                text-align: left;
                font-weight: bold;
                color: var(--White, #ffffff);
              }

              .lower-text {
                margin-left: -66px;
                margin-top: 10px;
                font-size: 2rem;
                color: var(--Gray-light);
                text-align: left;
              }
            }
          }
          .upper-left-item3 {
            display: flex;
            gap: 15px;
            flex-shrink: 0;
            padding: 90px 20px;
            border-radius: 23px;
            background: #142f6b;
            justify-content: center;
            align-items: center;

            .left-image {
              width: 150px;
              height: 100px;
              margin-top: -95px;
              img {
                width: 100%;
                height: auto;
              }
            }

            .right-text {
              display: flex;
              flex-direction: column;

              .upper-text {
                font-size: 2.2rem;
                text-align: left;
                font-weight: bold;
                color: var(--White, #ffffff);
              }

              .lower-text {
                margin-left: -66px;
                margin-top: 10px;
                font-size: 2rem;
                color: var(--Gray-light);
                text-align: left;
              }
            }
          }

          .top-item {
            font-size: 44px;
            width: 100%;
            text-align: center;
            margin-bottom: 30px;
          }
        }

        .upper-right-div {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 100%;
          }
        }
      }

      .lower-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        gap: 40px;
        margin-left: 0px;
        padding-bottom: 80px;
        .lower-left-div {
          width: 90%;
          img {
            width: 100%;
          }
        }

        .lower-right-div {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 20px;
          align-items: center;

          .lower-right-item {
            display: flex;
            flex-direction: column;
            gap: 15px;
            align-items: center;

            .upper-text {
              color: var(--White, #fff);

              font-size: 2.9rem;
              font-style: normal;
              font-weight: 500;
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
            }

            .lower-text {
              font-size: 1.8rem;
              text-align: center;
              width: 100%;
            }
          }
        }
      }
    }

    .section-five {
      margin: auto;
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      background: #f7faff;
      .steps-text {
        color: var(--Text, #252525);

        font-size: 1.8rem;
        font-style: normal;
        font-weight: 600;
        margin-left: 0px;
      }
      .number-steps {
        color: var(--Primary, #1f5ee6);

        font-size: 2.9rem;
        font-style: normal;
        margin-left: 0px;
        font-weight: 600;
      }
      .one-two-three-steps {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        gap: 25px;
        .step-one {
          width: 289px;
          height: 272px;
          flex-shrink: 0;
          border-radius: 20px;
          background: #1d1d1d;
          display: flex;
          .left-step-one {
            padding: 15px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 15px;
            position: absolute;
            .step-count {
              border-radius: 3px;
              background: var(--White, #fff);
              display: inline-flex;
              padding: 2px 0px;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              color: var(--Text, #252525);

              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              max-width: 18%;
            }
            .instruction {
              color: var(--White, #fff);

              font-size: 24px;
              font-style: normal;
              font-weight: 300;
              width: 63%;
              line-height: 30px;
            }
            .logo-image {
              width: 72px;
              margin-top: 27px;
              img {
                width: 100%;
              }
            }
          }
          .right-step-one {
            margin: auto;
            .human-img {
              width: 160px;
              position: relative;
              margin-left: 112px;
              margin-top: 29px;
              img {
                width: 100%;
              }
            }
          }
        }
        .step-two {
          width: 260px;
          .step-count {
            border-radius: 3px;
            background: var(--White, #fff);
            display: inline-flex;
            padding: 2px 5px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: var(--Text, #252525);

            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            max-width: 18%;
          }
          .instruction {
            color: var(--White, #fff);

            font-size: 27px;
            font-style: normal;
            font-weight: 300;
            width: 80%;
            line-height: 30px;
          }
          .logo-image {
            width: 60px;
            // margin-top: 17px;
            img {
              width: 100%;
            }
          }
        }
        .step-three {
          width: 272px;
          padding: 15px;
          height: 242px;
          flex-shrink: 0;
          border-radius: 20px;
          background-color: var(--White, #ffffff);
          gap: 15px;
          display: flex;
          flex-direction: column;
          margin-bottom: 60px;
          .step-count {
            border-radius: 3px;
            background: var(--Blue-primary, #1f5ee6);
            display: inline-flex;
            padding: 2px 5px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: var(--White, #ffffff);

            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            max-width: 18%;
          }
          .instruction {
            color: var(--Black, #202020);

            font-size: 25px;
            font-style: normal;
            font-weight: 500;
            width: 72%;
            line-height: 30px;
          }
          .logo-image {
            width: 50px;
            // margin-top: 17px;
            img {
              width: 100%;
            }
          }
          .right-step-one {
            margin: auto;
            .Stars-img {
              width: 90px;
              position: relative;
              margin-left: 183px;
              margin-top: -90px;
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }

    .section-six {
      background: #e9eff9;
      padding-top: 60px;
      padding-bottom: 60px;
      .section-six-main {
        .heading-section-six {
          color: var(--Text, #252525);
          font-size: 2.3rem;
          font-style: normal;
          font-weight: 600;
          width: 90%;
          text-align: center;
          margin: auto;
          margin-bottom: 30px;
        }
        // .reviews-wrapper {
        //   overflow: hidden;
        // width: 100%;
        // position: relative;
        // display: flex;
        // gap: 20px;
        // margin: auto;
        //   .reviews {
        //     display: flex;
        //     gap: 20px;
        //     justify-content: space-around;
        //     flex-direction: column;
        //     .card-reviews {
        //       width: 33%;
        //       gap: 0px;
        //       display: flex;
        //       flex-direction: column;
        //       border-radius: 23px;
        //       background-image: url("../../images/review.svg"); /* Add the background image here */
        //       background-size: cover; /* Ensures the background covers the whole card */
        //       background-position: center; /* Center the image in the background */
        //       padding: 15px;
        //       color: white; /* Ensure text is readable on the background image */
        //       position: relative; /* Positioning for the content over the image */

        //       .upper {
        //         display: flex;
        //         gap: 10px;

        //         .left-img {
        //           width: 80px;
        //           img {
        //             width: 100%;
        //           }
        //         }

        //         .right-content {
        //           display: flex;
        //           flex-direction: column;
        //           justify-content: center;
        //           text-align: left;
        //           gap: 5px;
        //           .name {
        //             color: #000;

        //             font-size: 1.6rem;
        //             font-style: normal;
        //             font-weight: 600;
        //             line-height: 100%;
        //           }

        //           .bussiness-type {
        //             color: #909090; /* Light grey for readability */

        //             font-size: 1.2rem;
        //             font-style: normal;
        //             font-weight: 400;
        //           }
        //         }
        //       }
        //       .line {
        //         img {
        //           width: 100%;
        //         }
        //       }
        //       .lower {
        //         color: #909090; /* Adjust text color for readability */

        //         font-size: 1.2rem;
        //         font-style: normal;
        //         font-weight: 400;
        //       }

        //       .review-stars {
        //         width: 200px;
        //         margin-top: 70px;
        //         img {
        //           width: 60%;
        //         }
        //       }
        //     }
        //   }
        // }
      }
    }
  }
}

// Extra large screens (1200px and up)
@media (min-width: 1200px) and (max-width: 1600px) {
}
