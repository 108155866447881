// Define color variables
$navbar-background: #f7faff;
$navbar-text-color: #202020;
$navbar-font: "Lexend", sans-serif;
$button-background: #1f5ee6;
$button-hover-background: #174bb8;

// Navbar container styling
.navbar {
 
  width: 100%;
  height: 152px;
  display: flex;
  text-align: center;
  justify-content: center;

  .logo-btn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    background: $navbar-background;
    box-sizing: border-box;

    // Left side: Logo and company name
    .navbar-left {
      display: flex;
      align-items: center;
      gap: 12px; // Space between logo and text
      margin-left: 40px;

      .navbar-logo {
        width: 46px;
        height: 48px;
        flex-shrink: 0;
      }

      .navbar-company-name {
        color: $navbar-text-color;
        font-family: $navbar-font;
        font-size: 34.885px;
        font-weight: 400;
        line-height: normal;
        text-decoration: none; // Remove underline from company name
      }

      // Ensure text-decoration is applied when the logo and name are wrapped with a link
      a {
        text-decoration: none;
        align-items: center;
        display: flex;
        gap: 15px;
      }
    }

    // Right side: Try for Free button
    .navbar-right {
      display: flex;
      align-items: center;

      .navbar-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 32px;
        border-radius: 6px;
        background: $button-background;
        color: #fff;
        font-family: $navbar-font;
        font-size: 18px;
        font-weight: 400;
        border: none;
        cursor: pointer;
        text-decoration: none;
        margin-right: 40px;
        transition: background 0.3s ease;

        &:hover {
          background: $button-hover-background; // Darken on hover
        }
      }

      a {
        text-decoration: none; // Remove underline from the link wrapping the button
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 348.98px) {
  .navbar {
    width: 100%;
height: 75px;
    .logo-btn {
      padding: 0 10px;
      .navbar-left {
        gap: 6px;
        margin-left: 0px;
        .navbar-logo {
          width: 22px;
          height: 24px;
          flex-shrink: 0;
        }
        .navbar-company-name {
          font-size: 20.885px;
        }
        a {
          gap: 7px;
        }
      }
      .navbar-right {
        display: block;
        margin-right: 0px;

        .navbar-btn {
          display: block;
          padding: 10px 18px;
          font-size: 8px;
          margin-right: 0px;
        }
      }
    }
  }
}

// Small devices (portrait phones, less than 576px)
@media (min-width: 349px) and (max-width: 575.98px) {
  .navbar {
    height: 130px;
    .logo-btn {
      padding: 0 15px;
      .navbar-left {
        gap: 6px;
        margin-left: 0px;
        .navbar-logo {
          width: 26px;
          height: 28px;
          flex-shrink: 0;
        }
        .navbar-company-name {
          font-size: 24.885px;
        }
        a {
          gap: 7px;
        }
      }
      .navbar-right {
        display: block;
        align-items: center;
        margin-right: 0px;
        .navbar-btn {
          display: block;
          padding: 5px 10px;
          font-size: 16px;
          margin-right: 0px;
        }
      }
    }
  }
}

// Medium devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  .navbar {
    height: 130px;
    .logo-btn {
      padding: 0 15px;
      .navbar-left {
        gap: 6px;
        margin-left: 10px;
        .navbar-logo {
          width: 30px;
          height: 32px;
          flex-shrink: 0;
        }
        .navbar-company-name {
          font-size: 26.885px;
        }
        a {
          gap: 7px;
        }
      }
      .navbar-right {
        display: block;
        align-items: center;
        margin-right: 10px;
        .navbar-btn {
          display: block;
          padding: 5px 10px;
          font-size: 16px;
          margin-right: 0px;
        }
      }
    }
  }
}

// Large devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  .navbar {
    height: 130px;
    .logo-btn {
      padding: 0 15px;
      .navbar-left {
        gap: 6px;
        .navbar-logo {
          width: 42px;
          height: 42px;
          flex-shrink: 0;
        }
        .navbar-company-name {
          font-size: 30.885px;
        }
        a {
          gap: 10px;
        }
      }
      .navbar-right {
        display: block;
        align-items: center;
        margin-right: 10px;
        .navbar-btn {
          padding: 8px 14px;
        }
      }
    }
  }
}

// Extra large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .navbar {
    height: 130px;
    .logo-btn {
      padding: 0 15px;
      .navbar-left {
        gap: 6px;
        .navbar-logo {
          width: 42px;
          height: 42px;
          flex-shrink: 0;
        }
        .navbar-company-name {
          font-size: 30.885px;
        }
        a {
          gap: 10px;
        }
      }
      .navbar-right {
        display: block;
        align-items: center;
        margin-right: 10px;
        .navbar-btn {
          padding: 8px 14px;
        }
      }
    }
  }
}

// Extra large screens (1200px and up)
// @media (min-width: 1200px) and (max-width: 1600px) {
// }
