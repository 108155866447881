.main-contact-us {
  width: 789px;
  border-radius: 29px;
  border: 1px solid #d0dffe;
  background: var(--White, #fff);
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 40px;

  .bottom-contactus {
    display: flex;
    justify-content: center;
    margin: auto;
    margin-top: 20px;
    .left {
      .top-contactus {
        color: var(--Primary, #1f5ee6);
        
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .form-group {
        line-height: 40px;
        label {
          color: var(--Text, #252525);
          
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
        }
        input {
          display: flex;
          width: 328px;
          padding: 9px 15px;
          align-items: center;
          gap: 10px;
          border-radius: 5px;
          border: 1px solid var(--Text, #252525);
          background: var(--White, #fff);
        }
        textarea {
          display: flex;
          width: 328px;
          height: 137px;
          padding: 9px 15px;
          align-items: flex-start;
          gap: 10px;
          border-radius: 5px;
          border: 1px solid var(--Text, #252525);
          background: var(--White, #fff);
          resize: none;
          overflow: auto;
        }
        .error-message {
        }
      }
      button {
        display: inline-flex;
        padding: 10px 20px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 6px;
        background: var(--Primary, #1f5ee6);
        color: var(--White, #fff);
        
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: 30px;
      }
    }
    .right {
      img {
        width: 100%;
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 348.98px) {
  .main-contact-us {
    width: 80%;
    padding: 0px;
    margin-top: 130px;
    .bottom-contactus {
      flex-direction: column-reverse;
      padding: 15px;
      align-items: center;
      margin-top: 0px;
      .left {
        width: 80%;
        .top-contactus {
          font-size: 1.8rem;
          padding: 15px;
          font-weight: 600;
          width: 90%;
          text-align: center;
          padding-top: 30px;
        }
        .form-group {
          line-height: 40px;
          label {
            color: var(--Text, #252525);
            
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
          }
          input {
            width: 90%;
          }
          textarea {
            width: 90%;
          }
          .error-message {
          }
        }
        button {
          padding: 4px 8px;
          font-size: 0.8rem;
          margin-top: 15px;
        }
      }
      .right {
        margin-top: -120px;
        width: 70%;
        img {
          width: 100%;
          margin-top: -144px;
          display: flex;
          margin: auto;
        }
      }
    }
  }
}

// Small devices (portrait phones, less than 576px)
@media (min-width: 349px) and (max-width: 575.98px) {
  .main-contact-us {
    width: 80%;
    padding: 0px;
    margin-top: 130px;
    .bottom-contactus {
      flex-direction: column-reverse;
      padding: 15px;
      align-items: center;
      margin-top: 0px;
      .left {
        width: 80%;
        .top-contactus {
          font-size: 2.2rem;
          padding: 15px;
          font-weight: 600;
          width: 90%;
          text-align: center;
          padding-top: 50px;
        }
        .form-group {
          line-height: 40px;
          label {
            color: var(--Text, #252525);
            
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
          }
          input {
            width: 90%;
          }
          textarea {
            width: 90%;
          }
          .error-message {
          }
        }
        button {
          padding: 4px 8px;
          font-size: 0.8rem;
          margin-top: 15px;
        }
      }
      .right {
        margin-top: -140px;
        width: 70%;
        img {
          width: 100%;
          margin-top: -144px;
          display: flex;
          margin: auto;
        }
      }
    }
  }
}

// Medium devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  .main-contact-us {
    width: 80%;
    padding: 0px;
    margin-top: 100px;
    .bottom-contactus {
      flex-direction: column-reverse;
      padding: 15px;
      align-items: center;
      margin-top: 0px;
      .left {
        width: 75%;
        .top-contactus {
          font-size: 2rem;
          padding: 15px;
          font-weight: 600;
          width: 90%;
          text-align: center;
          padding-top: 40px;
        }
        .form-group {
          line-height: 40px;
          label {
            color: var(--Text, #252525);
            
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
          }
          input {
            width: 95%;
          }
          textarea {
            width: 95%;
          }
          .error-message {
          }
        }
        button {
          padding: 10px 20px;
          font-size: 1rem;
          margin-top: 15px;
        }
      }
      .right {
        margin-top: -155px;
        width: 45%;
        img {
          width: 100%;
          display: flex;
          margin: auto;
        }
      }
    }
  }
}

// Large devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  .main-contact-us {
    width: 80%;
    padding: 0px;
    margin-top: 100px;
    .bottom-contactus {
      flex-direction: column-reverse;
      padding: 15px;
      align-items: center;
      margin-top: 0px;
      .left {
        width: 75%;
        .top-contactus {
          font-size: 2rem;
          padding: 15px;
          font-weight: 600;
          width: 90%;
          text-align: center;
          padding-top: 40px;
        }
        .form-group {
          line-height: 40px;
          label {
            color: var(--Text, #252525);
            
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
          }
          input {
            width: 95%;
          }
          textarea {
            width: 95%;
          }
          .error-message {
          }
        }
        button {
          padding: 10px 20px;
          font-size: 1rem;
          margin-top: 15px;
        }
      }
      .right {
        margin-top: -155px;
        width: 45%;
        img {
          width: 100%;
          display: flex;
          margin: auto;
        }
      }
    }
  }
}

// Extra large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
}
